<template>
	<div class="general">
        <div class="general-width">
            <GeneralHeader 
                :item="mission" 
                :type="'Mission'" 
                :title="mission.name"
                :validation_target="'name'"
                :can_edit="user_perms.find(e => e.name == 'edit_mission')" 
                :can_delete="user_perms.find(e => e.name == 'delete_mission')" 
                @edit="editMission($event)" 
                @delete="deleteMission($event)" 
            />
            <MissionInfo 
                :mission="mission" 
                :user_perms="user_perms"
            />
        </div>
    </div>
</template>

<script>
import GeneralHeader from '@/components/admin/layouts/GeneralHeaderCard'
import MissionInfo from '@/components/admin/missions/MissionInfo'
import axios from 'axios'
import { useMyStore } from '@/store'

export default {
    name:"MissionPage",
    components:{
        GeneralHeader,
        MissionInfo
    },
    props:['user_perms'],
    beforeMount (){
        axios.get(`${useMyStore().api_url}/admin/mission/${this.$route.params.id}`)
            .then(response => {
                this.mission = response.data.data
            }).catch(e => {
                console.log(e);
            });
    },
    data (){
        return {
            mission : {}
        }
    },
    methods: {
        editMission: function(input_mission){
            axios.put(`${useMyStore().api_url}/admin/mission/${this.$route.params.id}`, input_mission)
                .then((response) => {
                    Object.assign(this.mission, response.data.data);
                    // this.mission.name = data.name;
                    // this.mission.description = data.description;
                    // this.mission.location = data.location;
                    // this.mission.start_date = data.start_date;
                    // this.mission.end_date = data.end_date;
                    // this.mission.active_mission = data.active_mission;
                    // this.mission.is_public = data.is_public;
                    useMyStore().addNotification({
                        type: 'success',
                        title: 'Edited Mission'
                    })
                }).catch(e => {
                    console.log(e);
                    useMyStore().addNotification({
                        type: 'error',
                        title: `Error editing mission`
                    })
                });
        },
        deleteMission: function(){
            axios.delete(`${useMyStore().api_url}/admin/mission/${this.$route.params.id}`)
                .then(() => {
                    this.$router.push({name:'AdminMissions'})
                    useMyStore().addNotification({
                        type: 'success',
                        title: 'Deleted mission'
                    })
                }).catch(e => {
                    console.log(e);
                    useMyStore().addNotification({
                        type: 'error',
                        title: `Error deleting mission`
                    })
                });
        }
    },
}
</script>