<template>
    <v-card class="mx-auto" elevation="16" min-width="50vw" max-width="min-content" min-height="fit-content" style="display: flex;
    flex-direction: column;">


       
            <v-card-title>
                <div class="header">
                    <div class="image image1">
                        <v-img max-width="100%" height="100%" aspect-ratio="16/9" cover src="/siani_large_logo.png"
                            @click="goToExternalPage('https://www.siani.es/')"></v-img>
                    </div>

                    <div class="image image2">
                        <v-img  max-width="100%" height="100%" aspect-ratio="1/1" cover src="/logo-ULPGC-1.png"
                        @click="goToExternalPage('https://www.ulpgc.es/')"></v-img>
                    </div>

                    <div class="title">
                        <div v-if="!isEditable" v-html="formattedContent(titleValue)" >
                        </div>
                        <v-text-field v-else v-model="titleValue" label="Editing title" variant="solo"/>
                    </div>
                </div>
            </v-card-title>

       

        <v-card-text >
            <div v-if="!isEditable" v-html="formattedContent(textValue)" >
            </div>

            <v-textarea v-else label="Editing text" persistent-hint 
                hint="Use **bold text** to make text bold. Example: This is **bold** text."  class="mb-2"
                rows="10" min-width="90%" no-resize variant="solo" v-model="textValue" counter="1300"></v-textarea>

            <div v-if="isAdmin" class="text-actions">
                <v-btn color="warning" :width="buttonsWidth" v-if="!isEditable" @click="editText()">Edit</v-btn>
                <template v-else>
                    <v-btn color="error" :width="buttonsWidth" @click="textEdited(false)"> Cancel </v-btn>
                    <v-btn color="success" :width="buttonsWidth" @click="textEdited(true)"> Save </v-btn>
                </template>

            </div>

        </v-card-text>






    </v-card>

</template>

<script >
import { useMyStore } from '@/store';
import axios from 'axios';
import { ref, onMounted } from 'vue';
import DomPurify from 'dompurify';

export default {
    name: "InfoModal",
    data() {
        return {
            buttonsWidth : "10vw"
        }
    },
    props: {
        isAdmin : Boolean
    },
    setup() {
        const isEditable = ref(false);
        const textValue = ref(``);
        const titleValue = ref(``);
        

        const goToExternalPage = (url) => {
            window.open(url, '_blank');
        }

        let lastTextValue = "";
        let lastTilteValue = "";
        const editText = () => {
            isEditable.value = true;
            lastTextValue = textValue.value;
            lastTilteValue = titleValue.value;
        }

        const textEdited = (confirm) => {
            isEditable.value = false;
            if (confirm) {
                axios.put(`${useMyStore().api_url}/admin/general-data/updateData`, {
                    
                    dataKey: "aboutText",
                    value : textValue.value
                    
                }).catch((reason) => {
                    console.error(reason);
                });

                axios.put(`${useMyStore().api_url}/admin/general-data/updateData`, {
                    
                    dataKey: "aboutTitle",
                    value : titleValue.value
                    
                }).catch((reason) => {
                    console.error(reason);
                });

                return;
            }
            textValue.value = lastTextValue;
            titleValue.value = lastTilteValue;
        } 

        const transformLinks = (text) => {
            const urlPattern = /(\b(https?|ftp|file):\/\/[-A-Z0-9+&@#\/%?=~_|!:,.;]*[-A-Z0-9+&@#\/%=~_|])/ig;
            return text.replace(urlPattern, (url) => {
                return `<a href="${url}" target="_blank" rel="noopener noreferrer">${url}</a>`;
            });
        }

        const tranformsBlack = (text) => {
            const boldPattern = /\*\*(.*?)\*\*/g;
            return text.replace(boldPattern, (match, p1) => {
                return `<strong>${p1}</strong>`;
            });
        }

        const formattedContent = (value, elementTipe = 'p') => {
        
            let result = `<${elementTipe}>` + value + `</${elementTipe}>`;
            result = result.replace(/\n/g, '<br>');
            result = transformLinks(result);
            result = tranformsBlack(result);
            return DomPurify.sanitize(result);
        }

        onMounted(() => {
            axios.get(`${useMyStore().api_url}/admin/general-data/getData`,{params : {
                dataKey : "aboutText"
            }}).then((response) => {
                textValue.value = response.data.value;
            }).catch((reason) => {
                console.error(reason);
            });

            axios.get(`${useMyStore().api_url}/admin/general-data/getData`,{params : {
                dataKey : "aboutTitle"
            }}).then((response) => {
                titleValue.value = response.data.value;
            }).catch((reason) => {
                console.error(reason);
            });
        })
        return{
            textValue,
            titleValue,
            isEditable,
            goToExternalPage,
            editText,
            textEdited,
            formattedContent
        }
    }
}
</script>

<style scoped>
  

    .header {
        display: grid;
        grid-template-areas:
            "image1 image2"
            "title title";
        grid-template-columns: 80% 1fr;
        grid-template-rows: 70% 1fr;
        gap: 5px;
    }

    .text-actions{
        display: flex;
        flex-direction: row-reverse;
        justify-content: space-evenly;
        padding-top: 5%
    }

    .title {
        grid-area: title;
    }

    .image1 {
        grid-area: image1;
    }

    .image2 {
        grid-area: image2;
    }

    .image {
        cursor: pointer;
        height: auto;
        align-self: center;
    }

    .text{
        color: black;
        font-size:medium;
        white-space: pre-wrap;
        height: auto;
    }
</style>