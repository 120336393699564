



export const dividePolyline = (records, interval) => {
  records = JSON.parse(records);

  
  const max_distance = interval * 20;


  const calculateDistance = (lat1, lon1, lat2, lon2) => {
    const R = 6371e3; // Radio de la Tierra en metros
    const φ1 = (lat1 * Math.PI) / 180; // φ, latitud en radianes
    const φ2 = (lat2 * Math.PI) / 180;
    const Δφ = ((lat2 - lat1) * Math.PI) / 180;
    const Δλ = ((lon2 - lon1) * Math.PI) / 180;

    const a =
      Math.sin(Δφ / 2) * Math.sin(Δφ / 2) +
      Math.cos(φ1) * Math.cos(φ2) * Math.sin(Δλ / 2) * Math.sin(Δλ / 2);
    const c = 2 * Math.atan2(Math.sqrt(a), Math.sqrt(1 - a));

    const distance = R * c; // Distancia en metros
    return distance;
  };
  const linesArray = [];
  let lineArray = [];
  let lastPoint = null;
  records.forEach((record) => {
    if (record === 0) {
      linesArray.push([...lineArray]);
      lineArray = [];
      return;
    }

    if (lastPoint === null) {
      lastPoint = [record.latitude, record.longitude];
      lineArray.push([...lastPoint]);
      return;
    }

    const distance = calculateDistance(
      lastPoint[0],
      lastPoint[1],
      record.latitude,
      record.longitude
    );
    if (distance > max_distance) {
      linesArray.push([...lineArray]);
      lineArray = [];
    }
    lastPoint = [record.latitude, record.longitude];
    lineArray.push([...lastPoint]);
  });

  if (lineArray.length > 0) {
    linesArray.push([...lineArray]);
  }
  return JSON.stringify(linesArray);
};

