<template>
  <l-map ref="map" :class="['map', 'hide_zoom_control']" :zoom="zoom" :center="center" @focus="$emit('mapFocused')"
    @blur="$emit('mapUnfocused')" @update:center="centerUpdated" @update:zoom="zoomUpdated"
    @update:bounds="boundsUpdated">
    <l-tile-layer :url="urlStreetMap" layer-type="base" name="OpenStreetMap" attribution="
        &copy; <a href='https://www.openstreetmap.org' target='_blank'>OpenStreetMap Contributors<a>, 
        &copy; <a href='https://emodnet.ec.europa.eu/en/bathymetry' target='_blank'>EMODnet Contributors<a>">

    </l-tile-layer>
    <!-- <l-wms-tile-layer v-if="layers.includes('showDepthContour')"  key="Depth data" name="Depth data" format="image/png"
      url="https://depth.openseamap.org/geoserver/openseamap/wms" :transparent=true :visible=true
      layers="openseamap:contour2,openseamap:contour"></l-wms-tile-layer>

    <l-wms-tile-layer v-if="layers.includes('showMarineProfile')" key="Depth data 2" name="Depth data 2" format="image/png"
      url="https://geoserver.openseamap.org/geoserver/gwc/service/wms" :transparent=true :visible=true
      layers="gebco2021:gebco_2021"></l-wms-tile-layer> -->

    <l-wms-tile-layer v-if="layers.includes('Bathymetric contours')" key="Depth data " name="Depth data "
      format="image/png" url="https://ows.emodnet-bathymetry.eu/wms?SERVICE=WMS&REQUEST=GetCapabilities&VERSION=1.3.0"
      :transparent=true :visible=true layers="emodnet:contours"></l-wms-tile-layer>

    <l-wms-tile-layer v-if="layers.includes('Mean depth in multi colour (no land)')" key="Depth data "
      name="Depth data " format="image/png"
      url="https://ows.emodnet-bathymetry.eu/wms?SERVICE=WMS&REQUEST=GetCapabilities&VERSION=1.3.0" :transparent=true
      :visible=true layers="emodnet:mean_multicolour"></l-wms-tile-layer>

    <l-wms-tile-layer v-if="layers.includes('High Resolution bathymetry')" key="Depth data " name="Depth data "
      format="image/png" url="https://ows.emodnet-bathymetry.eu/wms?SERVICE=WMS&REQUEST=GetCapabilities&VERSION=1.3.0"
      :transparent=true :visible=true layers="emodnet:hr_bathymetry"></l-wms-tile-layer>

    <l-wms-tile-layer v-if="layers.includes('Mean depth rainbow colour (no land)')" key="Depth data " name="Depth data "
      format="image/png" url="https://ows.emodnet-bathymetry.eu/wms?SERVICE=WMS&REQUEST=GetCapabilities&VERSION=1.3.0"
      :transparent=true :visible=true layers="emodnet:mean_rainbowcolour"></l-wms-tile-layer>


    <l-control-scale position="bottomleft" />

    <template v-for="mission in missions">
      <div>
        <l-marker
          v-if="mission.vehicle?.records && mission.vehicle?.records.length > 0 && mission.vehicle?.records[mission.vehicle.current_playing_record] != 0"
          :lat-lng="[mission.vehicle?.records[mission.vehicle.current_playing_record].latitude, mission.vehicle?.records[mission.vehicle.current_playing_record].longitude]"
          @click="$emit('showInfo', mission.vehicle)">
          <l-icon :class-name="`boat-${mission.vehicle?.name}-${mission.name}-icon`" :icon-size="dynamicSize"
            :icon-anchor="dynamicAnchor">
            <!-- SVG para el marcador del barco -->
            <svg
              :style="{ transform: `rotate(${mission.vehicle?.records[mission.vehicle?.current_playing_record].heading}deg)` }"
              viewBox="0 0 16.46 55.55">
              <path
                :style="{ fill: mission.vehicle?.boat_mark_color === '' ? '#002885' : mission.vehicle?.boat_mark_color, stroke: '#1d1d1b' }"
                class="boat_marker"
                d="M15.46,45.26c0-23.93-3-38.2-5.86-42.78C8.31.39,8.19.6,6.9,2.55,4,6.88,1,20.91,1,45.26,1.21,57.39,15.46,57.9,15.46,45.26Z" />
            </svg>

            <!-- SVG para la dirección y velocidad del viento -->
            <svg v-if="show_wind"
              :style="{ transform: `translate(-50%, -85%) rotate(${mission.vehicle?.records[mission.vehicle?.current_playing_record].true_wind_angle}deg)` }"
              class="wind_svg" viewBox="0 0 12.79 27.15">
              <!-- Triángulos de velocidad del viento -->
              <path
                v-for="index in getTriangles(mission.vehicle?.records[mission.vehicle?.current_playing_record].true_wind_speed)" 
                :key="index" class="wind_svg_velocity_triangle"
                d="M27.47,1.13c.16.17-11.8,8.65-11.8,8.65V6.61S27.3,1,27.47,1.13Z"
                :transform="`translate(-15.17 ${index * 4 - 4})`" />
              <!-- Líneas largas de velocidad del viento -->
              <line
                v-for="index in getLargeLines(mission.vehicle?.records[mission.vehicle?.current_playing_record].true_wind_speed)"
                :key="index" class="wind_svg_velocity_long_line" x1="0.26" y1="6.61" x2="12.18" y2="0.66"
                :transform="`translate(0 ${index * 2 - 2 + Math.floor(mission.vehicle?.records[mission.vehicle?.current_playing_record].true_wind_speed / 50) * 4.3})`" />
              <!-- Líneas cortas de velocidad del viento -->
              <line
                v-for="index in getShortLines(mission.vehicle?.records[mission.vehicle?.current_playing_record].true_wind_speed)"
                :key="index" class="wind_svg_velocity_short_line" x1="0.26" y1="6.61" x2="6.33" y2="3.58"
                :transform="`translate(0 ${index * 2 - 2 + Math.floor(mission.vehicle?.records[mission.vehicle?.current_playing_record].true_wind_speed / 50) * 4.3 + Math.floor((mission.vehicle?.records[mission.vehicle?.current_playing_record].true_wind_speed % 50) / 10) * 2})`" />
              <!-- Línea de ángulo del viento -->
              <path class="wind_svg_angle_line" :style="{ fill: '#fff', stroke: '#1d1d1b' }" d="M16.17,6.81v21"
                transform="translate(-15.17 -0.63)" />
            </svg>
          </l-icon>


        </l-marker>
      </div>


    </template>

    <template v-if="show_boats_route">
      <template v-for="mission in missions">
        <l-polyline
          v-if="mission.vehicle?.records && mission.vehicle?.records.length > 0 && mission.vehicle?.latLngs[mission.vehicle.current_playing_record] != 0"
          v-for="line in mission.vehicle.latLngs" :key="`1-${mission.id}-${mission.vehicle.id}`" :lat-lngs="line"
          :color="mission.vehicle.boat_mark_color == '' ? '#002885' : hexOpposite(mission.vehicle?.boat_mark_color)"
          dashArray="5,10"></l-polyline>
      </template>
    </template>

    <template v-if="show_waypoints">
      <template v-for="mission in missions">
        <div
          v-if="mission.vehicle?.records && mission.vehicle?.records.length > 0 && mission.vehicle?.records[mission.vehicle.current_playing_record] != 0"
          :key="`5-${mission.id}-${mission.vehicle.id}`">

          <l-marker v-for="waypoint, index in spliceRepeatedWaypoint(mission.waypoints)"
            :key="`4-${mission.id}-${mission.vehicle.id}-${waypoint.id}`" :lat-lng="waypoint">

            <l-tooltip>
              <p><b>Waypoint - {{ index }} -</b></p>
              <p v-if="index === 0">'Return to home' waypoint</p>
              <p><b>Latitude :</b> {{ waypoint[0] }}</p>
              <p><b>Longitude :</b> {{ waypoint[1] }}</p>
            </l-tooltip>
            <l-icon class-name="" :iconSize="[70, 70]">
              <v-icon
                :color="index === 0 ? hexOpposite(mission.vehicle?.boat_waypoint_color) : mission.vehicle?.boat_waypoint_color"
                size="50">mdi-map-marker</v-icon>
            </l-icon>

          </l-marker>
          <l-polyline ref="polyline" :lat-lngs="mission.waypoints.latLngs.slice(1)"
            :color="mission.vehicle.boat_waypoint_color == '' ? '#002885' : mission.vehicle.boat_waypoint_color"></l-polyline>
        </div>
      </template>
    </template>
    <template v-if="show_next_waypoint">
      <template v-for="mission in missions">
        <div
          v-if="mission.vehicle?.records && mission.vehicle?.records.length > 0 && mission.vehicle?.records[mission.vehicle.current_playing_record] != 0"
          :key="`2-${mission.id}-${mission.vehicle.id}`">
          <l-marker
            :lat-lng="[mission.vehicle.records[mission.vehicle.current_playing_record].active_wp_latitude, mission.vehicle.records[mission.vehicle.current_playing_record].active_wp_longitude]">
            <l-icon class-name="" :iconSize="[70, 70]">
              <v-icon :color="hexOpposite(mission.vehicle?.boat_waypoint_color)" size="50">mdi-map-marker</v-icon>
            </l-icon>

          </l-marker>
          <l-polyline
            :lat-lngs="[[mission.vehicle.records[mission.vehicle.current_playing_record].active_wp_latitude, mission.vehicle.records[mission.vehicle.current_playing_record].active_wp_longitude], [mission.vehicle.records[mission.vehicle.current_playing_record].latitude, mission.vehicle.records[mission.vehicle.current_playing_record].longitude]]"
            :color="'#002885'"></l-polyline>
        </div>
      </template>
    </template>
  </l-map>
</template>
  
  <script>
  import { ref, computed, onMounted,watch, onErrorCaptured} from 'vue';
  import "leaflet/dist/leaflet.css";
  import { LMap, LTileLayer, LIcon, LPolyline, LWmsTileLayer, LControlScale, LControlLayers, LTooltip} from '@vue-leaflet/vue-leaflet';
  import { LMarker } from "vue-leaflet-rotatedmarker";
  import { useInventoryStore } from '@/store';
  import L from 'leaflet';
  import 'leaflet-textpath';
 


  export default {
    name: 'Map',
    components: {
      LMap,
      LTileLayer,
      LIcon,
      LPolyline,
      LMarker,
      LWmsTileLayer,
      LControlScale,
      LControlLayers,
      LTooltip
    },
    props: ['show_boats_route', 'show_next_waypoint',
      'show_waypoints', 'show_wind', 'zoomControl', 'layers'],
    setup() {
      const urlStreetMap = ref('https://tile.openstreetmap.org/{z}/{x}/{y}.png');
      const zoom = ref(13);
      const center = ref([28.143732, -15.456884]);
      const bounds = ref(null);
      const iconSize = ref(13);
      const map = ref({});
      const polyline = ref([]);
      

      const inventoryStore = useInventoryStore();
      const missions_ref = computed(() => inventoryStore.playing_missions);
      const missions = ref(missions_ref.value);

      

      const zoomUpdated = (zoom) => {
        //console.log(`New zoom: ${zoom}`);
      };

      const centerUpdated = (_) => {
        //console.log(`New center: ${center.value}`);
      };

      const boundsUpdated = (bounds) => {
        // console.log(`New bounds: ${bounds}`);
      };

      const updatePosition = (latLng) => {
        map.value.leafletObject.flyTo(latLng);
      }

      watch(polyline.value, value => {
        if(value.length != 0) {
          polyline.value[0]?.leafletObject.setText('  ►  ', {repeat: true, center: true, attributes: {fill: polyline.value[0]?.leafletObject.options.color}});
        }
      })  
      
      let positionInterval = null;

      watch(inventoryStore.followingMission, value => {
        if(value[0] != -1){
          clearInterval(positionInterval);
          positionInterval = setInterval(() => {
            map.value.leafletObject.panTo(inventoryStore.getPlayingMissionPosition());
          }, 100);

        } else {
          clearInterval(positionInterval);
          positionInterval = null;
        }
      })
      

      const spliceRepeatedWaypoint = (waypoints) => {
        if(!waypoints.is_cyclic) {
          return waypoints.latLngs;
        }
        return waypoints.latLngs.slice(0, waypoints.latLngs.length - 1);
      }

      const dynamicSize = computed(() => {
        return [iconSize.value, iconSize.value * 2.82];
      });

      const dynamicAnchor = computed(() => {
        return [iconSize.value / 2, iconSize.value * 1.5];
      });

      
      const hexToRgb = (hex) => {
          hex = hex.replace(/^#/, '');
          let bigint = parseInt(hex, 16);
          let r = (bigint >> 16) & 255;
          let g = (bigint >> 8) & 255;
          let b = bigint & 255;
          return [r, g, b];
      }

      const rgbToHex = (r, g, b) => {
          return '#' + [r, g, b].map(x => {
              let hex = x.toString(16);
              return hex.length === 1 ? '0' + hex : hex;
          }).join('');
      }

      const rgbOpposite = (r, g, b) => {
          return [255 - r, 255 - g, 255 - b];
      }

      const hexOpposite = (hex) => {
          let [r, g, b] = hexToRgb(hex);
          let [rOpp, gOpp, bOpp] = rgbOpposite(r, g, b);
          return rgbToHex(rOpp, gOpp, bOpp);
      }
      
      const getShortLines = (wind) => {
        
        const amount = wind / 10;
        const integerPart = Math.trunc(amount);
        const decimalPart = amount - integerPart;
        if(decimalPart <= 0.5 && decimalPart > 0) {
          return 1
        } else return 0 ;
      }

      const getLargeLines = (wind) => {
        const amount = wind / 10;
        let integerPart = Math.trunc(amount);
        const decimalPart = (amount - integerPart);
        let totalWind = wind;
        while(totalWind >= 50) {
          integerPart -= 5;
          totalWind -= 50;
        } 
        if(decimalPart > 0.5) {
          integerPart++;
        }
        console.log(integerPart, decimalPart);
        return integerPart;
      }

      const getTriangles = (wind) => {
        const amount = wind / 50;
        const integerPart = Math.trunc(amount);
        return integerPart;
      }

      return {
        urlStreetMap,
        zoom,
        center,
        bounds,
        iconSize,
        zoomUpdated,
        centerUpdated,
        boundsUpdated,
        dynamicAnchor,
        dynamicSize,
        missions,
        map,
        polyline,
        updatePosition,
        hexOpposite,
        spliceRepeatedWaypoint,
        getShortLines,
        getLargeLines,
        getTriangles
      };
    },
    errorCaptured(error) {
      console.log(error.message);
      this._.update();
    }
  }
  </script>
  
  <style lang="stylus">
  .hide_zoom_control .leaflet-control-zoom
      visibility hidden
  
  .boat_marker
      stroke-miterlimit 10
  
  .waypoint_marker
      stroke-miterlimit 10
  
  .waypoint_svg
      transform translate(-30%, -80%)
      height 35px
      width 35px
  
  .wind_marker
      stroke-miterlimit 10
      stroke-width 2px
  
  .wind_svg
      height 35px
      width 35px
      position absolute
      transform-origin 50% 185%
      top -50%
      left 0
  
      .wind_svg_velocity_triangle
          stroke black
          fill black
  
      .wind_svg_velocity_long_line
          stroke black
          fill black
  
      .wind_svg_velocity_short_line
          stroke black
          fill black
      .leaflet-top, .leaflet-bottom {
    position: absolute;
    z-index: -1000;
    pointer-events: none;
}
  </style>
  