<template>
	<div class="general">
		<div class="general-width">
            <GeneralList 
                :items="vehicles" 
				type="Vehicle"
				:item_general_info="item_general_info"
				:item_additional_info="item_additional_info" 
                :validation_target="'name'"
				:can_show="user_perms.find(e => e.name == 'show_vehicle')"
				:can_add="user_perms.find(e => e.name == 'create_vehicle')"
				:can_edit="user_perms.find(e => e.name == 'edit_vehicle')"
				:can_delete="user_perms.find(e => e.name == 'delete_vehicle')"
                @add="addVehicle($event)"
                @edit="editVehicle($event)"
                @delete="deleteVehicle($event)"
            />
		</div>
	</div>
</template>

<style scoped>
    
</style>

<script>
import GeneralList from '@/components/admin/lists/GeneralList.vue'
import axios from 'axios'
import { useMyStore } from '@/store'

export default {
    name:"AdminVehicles",
    components:{
        GeneralList,
    },
    beforeMount (){
        this.item_additional_info = []
		this.item_general_info = {
			should_redirect: true,
			fields: [
				{
					component: 'h2',
					target: 'name'
				}
			]
		}
        axios.get(`${useMyStore().api_url}/admin/vehicle/getAll`)
            .then(response => {
                this.vehicles = response.data.data
            }).catch(e => {
                console.log(e);
            });
    },
    data(){
        return {
            vehicles: []
        }
    },
    props:{
        user_perms : Array
    },
    methods: {
        addVehicle: function(input_vehicle){
            axios.post(`${useMyStore().api_url}/admin/vehicle`, input_vehicle)
                .then(response => {
                    this.vehicles = response.data.data
                    useMyStore().addNotification({
                        type: 'success',
                        title: 'Vehicle Added'
                    })
                }).catch(e => {
                    console.log(e);
                    useMyStore().addNotification({
                        type: 'error',
                        title: `Error adding vehicle`
                    })
                });
        },
        editVehicle: function(input_vehicle){
            axios.put(`${useMyStore().api_url}/admin/vehicle/${input_vehicle.id}`, input_vehicle)
                .then(response => {
                    this.vehicles = response.data.data
                    useMyStore().addNotification({
                        type: 'success',
                        title: 'Edited Vehicle'
                    })
                }).catch(e => {
                    console.log(e);
                    useMyStore().addNotification({
                        type: 'error',
                        title: `Error editing vehicle`
                    })
                });
        },
        deleteVehicle: function(input_vehicle){
            axios.delete(`${useMyStore().api_url}/admin/vehicle/${input_vehicle.id}`)
                .then(response => {
                    this.vehicles = response.data.data
                    useMyStore().addNotification({
                        type: 'success',
                        title: 'Deleted vehicle'
                    })
                }).catch(e => {
                    console.log(e);
                    useMyStore().addNotification( {
                        type: 'error',
                        title: `Error deleting vehicle`
                    })
                });
        }
    },
}
</script>