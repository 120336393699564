<template>
	<div class="container">
		<div class="controls-container" v-if="showButtons">
			<button @click="searchVehicles">
				<IconVehicles /> Vehicles
			</button>
			<button @click="searchMissions">
				<IconMissions /> Missions
			</button>
		</div>
		<div class="search-container">
			<RecomendSearchList v-if="showRecomendedList" :list="list" :type="listType" :missions="selectedMissions"
				@add="$emit('add', $event)" @search_by_vehicle="searchMissionByVehicle($event)" @hide="comeBack" />
		</div>
	</div>
</template>
  
<script>
import { ref } from 'vue';
import IconMissions from 'vue-material-design-icons/HelpRhombusOutline.vue';
import IconVehicles from 'vue-material-design-icons/SailBoat.vue';
import RecomendSearchList from './RecomendSearchList.vue';
import axios from 'axios';
import { useMyStore } from '@/store';
import { useInventoryStore} from '@/store'

export default {
	name: 'RecommendSearch',
	components: {
		IconMissions,
		IconVehicles,
		RecomendSearchList
	},
	emits : ['add'],
	props: ['missions', 'vehicles', 'selectedMissions'],
	setup() {
		const showButtons = ref(true);
		const showRecomendedList = ref(false);
		const list = ref([]);
		const listType = ref(false);

		const store = useMyStore();
		const inventory = useInventoryStore();

		axios.defaults.headers.common = {
            Authorization: `${localStorage.getItem('token')}`
        };
		const comeBack = () => {
			showRecomendedList.value = false;
			showButtons.value = true;
		}
		const searchVehicles = () => {
			showRecomendedList.value = true;
			showButtons.value = false;
			listType.value = 'vehicles';
			
			
			axios.get(`${store.api_url}/getAllVehicles`)
				.then(response => {
					inventory.updateVehicles(response.data.data);
					list.value = response.data.data;
				}).catch(e => {
					console.error(e.response);
				});
			
		};

		const searchMissions = () => {
			showRecomendedList.value = true;
			showButtons.value = false;
			listType.value = 'missions';
			
			axios.get(`${store.api_url}/getAllMissions`)
				.then(response => {
					inventory.updateMissions(response.data.missions)
					list.value = response.data.missions;
				}).catch(e => {
					console.error(e.response);
				});
			
		};

		const searchMissionByVehicle = (vehicle) => {
			showRecomendedList.value = false;
			listType.value = 'vehicle_mission';
			axios.get(`${store.api_url}/getMissionsByVehicle/${vehicle.id}`)
				.then(response => {
					inventory.updateMissions(response.data.missions);
					list.value = response.data.missions;
					showRecomendedList.value = true;
				}).catch(e => {
					console.error(e.response);
				});
		};

		return {
			comeBack,
			showButtons,
			showRecomendedList,
			list,
			listType,
			searchVehicles,
			searchMissions,
			searchMissionByVehicle
		};
	}
};
</script>

<style lang="stylus" scoped>
	@import("../../assets/css/generalColors.styl")
	.container
		padding 10px
		background-color third-color
		border-radius 10px
		margin-top 8px
		-webkit-box-shadow 0px 0px 11px 0px rgba(0,0,0,0.15)
		-moz-box-shadow 0px 0px 11px 0px rgba(0,0,0,0.15)
		box-shadow 0px 0px 11px 0px rgba(0,0,0,0.15)

		.controls-container
			display flex

			button
				flex-grow 1
				min-height  50px
				border none
				background-color second-color 
				border-radius 8px
				color third-color
				font-weight bold
				font-size 1.2rem
				cursor pointer
				display flex
				justify-content center
				align-items center

				&:not(:last-child)
					margin-right 10px
				&:hover
					background-color main-color
				svg	
					width 30px
					margin-right 5px

</style>
  