<template>
    <div class="text-center pa-4">
        <v-dialog v-model="dialogModel" max-width="400" persistent>
            <v-card text="You have unsaved changes, do you want to leave the page anyway?"
                title="Unsaved permissions">
                <template v-slot:actions>
                    <v-spacer></v-spacer>

                    <v-btn @click="$emit('cancel')">
                        Cancel
                    </v-btn>

                    <v-btn @click="$emit('confirm')">
                        Accept
                    </v-btn>
                </template>
            </v-card>
        </v-dialog>
    </div>
</template>

<script>
import {ref, watch} from 'vue';
export default {
    name : "ConfirmDialog",
    props : ["dialog"],
    emits: ['cancel', 'confirm'],
    setup(props) {
        const dialogModel = ref(props.dialog);
        watch(() => props.dialog, (value) => dialogModel.value = value);
        return {
            dialogModel
        }
    }
    
}
</script>

