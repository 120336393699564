import { createApp } from 'vue';
import App from './App.vue';
import router from './router'; 
import { createPinia } from 'pinia';
import { createVuetify } from 'vuetify';
import * as components from 'vuetify/components';
import '@mdi/font/css/materialdesignicons.css';
import { VNumberInput } from 'vuetify/labs/VNumberInput'


const pinia = createPinia();
const vuetify = createVuetify({
    theme: {
        defaultTheme: 'light'
    },
    components:{
        VNumberInput,
        ...components
    },
    //directives:directives,
    icons : {
        iconfont : 'mdi'
    },
    treeShake : true
});
const app = createApp(App);

app.use(pinia);
app.use(vuetify);
app.use(router);
app.mount('#app');

export default app;
