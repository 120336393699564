<template>
    <div>

        <v-table height="300px" fixed-header>
            <template v-slot:top>
                <p v-if="isPublic" style="color: darkgoldenrod;">
                    <strong>
                        This mission is marked as public,so the effect of the view permission of the mission would have no effect
                    </strong>
                </p>
            </template>

            <thead>
                <tr>
                    <th class="text-center">
                        User
                    </th>
                    <th class="text-center">
                        View permission
                    </th>
                    <th class="text-center">
                        Full access
                    </th>
                </tr>
            </thead>
            <tbody>
                <tr v-for="user in users" :key="user.name">
                    <td>{{ user.name }}</td>
                    <td><v-switch :disabled="isPublic" :readonly="usersPerms[user.id].full"
                            v-model="usersPerms[user.id].view" base-color="cancel" color="success"
                            :error="!usersPerms[user.id].view"></v-switch></td>
                    <td><v-switch v-model="usersPerms[user.id].full" color="success" :error="!usersPerms[user.id].full"
                            @update:modelValue="changePerms($event, user.id)"></v-switch></td>
                </tr>

            </tbody>
        </v-table>
    </div>
    <v-btn :disabled="!modified" color="success" :ripple="false" text="Confirmar" @click="savePerms"> </v-btn>
</template>

<script>
import { ref, watch, onBeforeUnmount} from 'vue';
import axios from 'axios';
import { useMyStore } from '@/store';
export default {
    name : "PermissionTable",
    props: ['missionId', 'missionOwner', 'isPublic'],
    emits: ['modified'],
    setup(props, ctx) {
        const users = ref([]);
        const usersPerms = ref({});
        const modified = ref(false);
        axios.defaults.headers.common = {
            Authorization: `${localStorage.getItem('token')}`
        };

        watch(modified, (value) => {
            ctx.emit('modified', value);
        })

        axios.
            get(`${useMyStore().api_url}/admin/user/getAll`).then((usersResponse) => {
                axios.get(`${useMyStore().api_url}/admin/mission/permission/getMissionPermissions`, {
                    params: {
                        mission_id: props.missionId
                    }
                }).then((permsResponse) => {
                    const perms = permsResponse.data.perms;
                    axios.get(`${useMyStore().api_url}/admin/role/getAll`)
                        .then(rolesResponse => {
                            const isRoleAdmin = rolesResponse.data.data.find(value => value.name === 'admin');
                            users.value.push(...usersResponse.data.data.filter((user) => user.id != props.missionOwner && user.role_id != isRoleAdmin.id ));
                            usersResponse.data.data.forEach(user => {

                                const userPerm = perms.find((value) => value.user_id === user.id);
                                usersPerms.value[user.id] = {
                                    view: userPerm?.permission === "View" || userPerm?.permission === "Full access",
                                    full: userPerm?.permission === "Full access",
                                    modified: false
                                }
                                watch(() => usersPerms.value[user.id].view, () => {
                                    modified.value = true;
                                    usersPerms.value[user.id].modified = true;
                                });
                            });
                        })

                });

            });

        const changePerms  = (active, userId) => {
            modified.value = true;
            usersPerms.value[userId].view = active;
        }

        const savePerms = () => {
            modified.value = false;
            const modifiedUsers = Object.keys(usersPerms.value).filter(key => usersPerms.value[key].modified);
            const perms = {};
            modifiedUsers.forEach((userId) => {
                usersPerms.value[userId].modified = false;
                if(usersPerms.value[userId].full) {
                    perms[userId] = "Full access";
                }else if(usersPerms.value[userId].view) {
                    perms[userId] = "View";
                }else {
                    perms[userId] = null;
                }

            })
            
            axios  
                .post(`${useMyStore().api_url}/admin/mission/permission/changePermissions`, {
                    users : perms,
                    mission_id : props.missionId
                });
        }
        return {
            users,
            usersPerms,
            modified,
            changePerms,
            savePerms
        }
    }
}
</script>