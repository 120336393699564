<template>
	<!-- <div :class="['mission-container', mission.is_loading ? 'loading':'']"> -->
	<div :class="['mission-container']">

		<!-- <div v-if="mission.is_loading" class="loader">Loading</div> -->
		<div class="tag">
			<div v-if="mission.active_mission" class="live">
				<svg viewBox="0 0 24 24">
					<path d="M12,2A10,10 0 0,0 2,12A10,10 0 0,0 12,22A10,10 0 0,0 22,12A10,10 0 0,0 12,2Z" />
				</svg>
			</div>
			<p>
				{{mission.name}}
			</p>
		</div>
		<v-slider :disable="mission.is_loading" class="bar" :min="0" :max="mission.count - 1" step="1" track-size="10"
			v-model="currentIndex" :color="mission.vehicles[0].boat_mark_color??'default'" hide-details>
			<template v-slot:append>
				<p>{{ new Date(mission.vehicle.records[mission.vehicle.current_playing_record].boat_time).toLocaleString() }}</p>

			</template>

		</v-slider>
		<div class="tools">


			<button @click="mission.current_playing = !mission.current_playing">
				<IconPlay v-if="!mission.current_playing" />

				<IconPause v-else />
			</button>


			<button @click="close()">
				<IconClose />
			</button>


			<button style="font-weight: bold; height: 100%; width: 32px; font-size: large;">
				{{ speed + 'X'}}
				<v-menu activator="parent" transition="slide-x-transition" location="end">
					<v-list style="background: rgba(255,255,255,0.5);">
						<v-list-item variant="plain"
							style="overflow: hidden; display: flex;justify-content: center;opacity: 100%;"
							v-for="(item, index) in speedOptions.filter(option => option !== speed) " :key="index"
							@click="speed = item">
							<v-list-item-title> <span style="font-weight: bold;">{{ item + 'X' }}</span>
							</v-list-item-title>
						</v-list-item>
					</v-list>
				</v-menu>
			</button>


			<button v-if="!followingMission">
				<v-icon size="large">mdi-crosshairs-gps</v-icon>

				<v-menu activator="parent" transition="slide-x-transition" location="end">
					<v-list style="background: rgba(255,255,255,0.5); overflow: hidden;" density="compact" slim>
						<v-list-item variant="plain" @click="goToMissionPosition">
							<v-list-item-title> <span style="font-weight: bold;">Center</span> </v-list-item-title>
						</v-list-item>
						<v-divider></v-divider>
						<v-list-item variant="plain" @click="followMissionPosition">
							<v-list-item-title> <span style="font-weight: bold;">Follow</span> </v-list-item-title>
						</v-list-item>
					</v-list>
				</v-menu>
			</button>

			<button v-else @click="followMissionPosition">
				<v-icon size="large" color="info">mdi-crosshairs-gps</v-icon>
			</button>
		</div>
	</div>
	<v-divider></v-divider>
</template>

<script>
import IconClose from 'vue-material-design-icons/CloseThick.vue'
import IconPlay from 'vue-material-design-icons/Play.vue'
import IconPause from 'vue-material-design-icons/Pause.vue'
import IconLowSpeed from 'vue-material-design-icons/SpeedometerSlow.vue'
import IconMediumSpeed from 'vue-material-design-icons/SpeedometerMedium.vue'
import IconLargeSpeed from 'vue-material-design-icons/Speedometer.vue'
import { onBeforeMount, watch, ref, computed, onBeforeUnmount } from 'vue';
import { useMyStore, useInventoryStore} from '@/store';
import {useWebWorkerFn} from '@vueuse/core';
import { dividePolyline } from '../../workers/calculatePolyline';
import { checkWaypoints } from '../../workers/checkWaypoints';
import axios from 'axios'
export default {
	name: 'SelectedMissionItem',
	components: {
		IconClose,
		IconPlay,
		IconPause,
		IconLowSpeed,
		IconMediumSpeed,
		IconLargeSpeed
	},
	props: ['mission'],
	emits: ['close', 'goToMissionPosition', 'changing_data'],
	setup(props, context) {
		const store = useMyStore();
		const inventory = useInventoryStore();
		let interval;
		const recordsDates = ref([]);
		const speed = ref(1);
		const speedOptions = ref([1, 2, 4, 8, 16, 32,64]);
		const currentIndex = ref(0);
		const followingMission = ref(false);

		const { workerFn : polylineWorkerFn, workerStatus : polylineWorkerStatus, workerTerminate : polylineWorkerTerminate } = useWebWorkerFn(dividePolyline);
		const { workerFn : waypointsWorkerFn, workerStatus : waypointsWorkerStatus, workerTerminate : waypointsWorkerTerminate } = useWebWorkerFn(checkWaypoints);

		window.addEventListener('keypress', (ev) => {
			if(ev.key === ' '){
				props.mission.current_playing = !props.mission.current_playing;
			}
		})

		const waitForCondition = async (conditionCallback) => {
			return new Promise((resolve, reject) => {
				const checkCondition = async () => {
					if (conditionCallback()) {
						resolve();
					} else {
						// Espera un breve período antes de verificar la condición nuevamente
						setTimeout(checkCondition, 50); 
					}
				};

				checkCondition();
			});
		};

		const updateRecords = async (indexValue) => {
			try {
				if (recordsDates.value[indexValue] === 0) {
					context.emit('changing_data', true);
					const page = store.pageNumber(indexValue);
					const response = await
						axios
							.get(`${useMyStore().api_url}/getRecordsFromMission/${props.mission.id}/${page}/${props.mission.recordsType}/${props.mission.interval}`);
					const first_index = page * store.items_per_page;
					for (let i = first_index; i < (first_index + response.data.vehicles.records.length); i++) {
						props.mission.vehicle.records[i] = response.data.vehicles.records[i - first_index];
						recordsDates.value[i] = response.data.vehicles.records[i - first_index].boat_time;
					}
				}
				waitForCondition(() => {
					return props.mission.vehicle.records[indexValue] !== 0
				})
					.then(() => {
						calculateCurrentRecordPos(indexValue);
					});

			} catch (error) {
				console.log(error.toString());
			}

		}

		const changePlayingDate = (indexValue) => {
			props.mission.current_playing_date = new Date(recordsDates.value[indexValue]).getTime();
		}

		const advancePlaybackTime = () => {
			if (props.mission.current_playing &&
				!props.mission.is_loading) {
					let new_position = props.mission.vehicle.current_playing_record + (speed.value >= 16 ? speed.value / 8 : 1);
					currentIndex.value = Math.min(recordsDates.value.length - 1, new_position, props.mission.vehicle.records.length - 1);
				
			}
		}


		let alternateFlag = true;
		const calculateCurrentRecordPos = (indexValue) => {
			changePlayingDate(indexValue);
			if((polylineWorkerStatus.value === 'PENDING' || polylineWorkerStatus.value === 'SUCCESS') && alternateFlag) {
				const new_records_latLngs = props.mission.vehicle.records.slice(0, indexValue + 1);
				polylineWorkerFn(JSON.stringify(new_records_latLngs),props.mission.interval).then((response) => {
					props.mission.vehicle.latLngs = JSON.parse(response).filter((value) => value.length > 0);
				})
			}
			alternateFlag != alternateFlag;
		};

		const change_playing_speed = (newSpeed) => {
			clearInterval(interval);
			if(newSpeed >= 16) newSpeed = 8;
			interval = setInterval(advancePlaybackTime, 1000 / newSpeed);
		}



		const close = () => {
			if(inventory.followingMission[0] === props.mission.id){
				inventory.followingMission[0] = -1;
			}
			clearInterval(interval);
			polylineWorkerTerminate();
			waypointsWorkerTerminate();
			context.emit('close', props.mission);
		}

		const goToMissionPosition = () => {
			context.emit('goToMissionPosition', props.mission);
		}

		const splitIntoDigits = (number = 1) => {
			return number.toString().split('')
		}

		const newPosition = async (value, oldvalue) => {
			if(props.mission.is_loading){
				//currentIndex.value = oldvalue;
				return;
			}
			props.mission.is_loading = true;
			if (value >= props.mission.count - 1) {
				if(!props.mission.active_mission){
					props.mission.current_playing = false;
				}
				speed.value = speedOptions.value[0];
			}
			await updateRecords(value)
			props.mission.vehicle.current_playing_record = value;

			if(props.mission.vehicle.waypoints.length != 0 && (waypointsWorkerStatus.value === 'PENDING' || waypointsWorkerStatus.value === 'SUCCESS') ) {
				waypointsWorkerFn(JSON.stringify(props.mission.vehicle.waypoints), props.mission.vehicle.records[value].boat_time).then((result) =>{
					props.mission.waypoints = {...JSON.parse(result)};
				});
			}
			props.mission.is_loading = false;
			context.emit('changing_data', false);
		}


		const followMissionPosition = () => {
			if(inventory.followingMission[0] === props.mission.id){
				inventory.followingMission[0] = -1;
				return;
			}
			inventory.followingMission[0] = props.mission.id;
		}

		
		


		onBeforeMount(() => {
			change_playing_speed(speedOptions.value[0]);

			let chargeInterval = setInterval(() => {
				if (props.mission.vehicle?.records != undefined) {
					recordsDates.value = new Array(props.mission.count).fill(0);

					recordsDates.value = props.mission.vehicle.records.map(record => record.boat_time ?? record);		

				
					changePlayingDate(0);
					if (props.mission.active_mission) {
						props.mission.vehicle.current_playing_record = recordsDates.value.length - 1;
						watch(props.mission.vehicle.records, (newValue, _) => {
							recordsDates.value[recordsDates.value.length] = newValue[newValue.length - 1].boat_time;
						});
					}

					clearInterval(chargeInterval);
				}
			}, 100);
		});




		watch(currentIndex, newPosition);
		watch(speed, change_playing_speed);

		
		watch(inventory.getFollowingMission(), (value) => {
			followingMission.value = (value[0] === props.mission.id)
		})

		return {
			splitIntoDigits,
			recordsDates,
			close,
			goToMissionPosition,
			speed,
			speedOptions,
			newPosition,
			currentIndex,
			followMissionPosition,
			followingMission
		}
	},
}
</script>

<style lang="stylus" scoped>
	@import("../../assets/css/generalColors.styl")
	$list-background = black
	.selected 
		background-color blue
	.mission-container
		border-radius 99999px
		margin-bottom 5px
		padding 0 10px
		display: grid
		grid-template "tag bar" "tag tools"
		grid-template-rows: 7vh 3vh;
		align-items center
		justify-content space-between
		background-color rgba(third-color, 0) 
		-webkit-box-shadow 0px 0px 11px 0px rgba(0,0,0,0)
		-moz-box-shadow 0px 0px 11px 0px rgba(0,0,0,0)
		box-shadow 0px 0px 11px 0px rgba(0,0,0,0)
		position relative

		.bar
			grid-area bar

		&.loading
			.tag, .tools
				filter blur(3px)
		.loader
			position absolute
			background-color rgba(0,0,0, .05)
			top 0
			left 0 
			width 100%
			height 100%
			z-index 200
			display flex
			justify-content center
			align-items center
			font-size .9rem
			font-weight bold

		.tag
			display: flex 
			margin-right 10px
			align-items center
			grid-area tag
			
			p
				cursor context-menu
				color second-color
				margin 0
				font-weight bold
				letter-spacing 0.5px
				font-size 0.8rem

			.live svg
				display flex
				height 10px
				width 10px
				margin-right 5px
				fill red

		.tools
			display: grid
			grid-template-columns: auto auto max-content auto;
			align-items center
			width 80vw
			grid-area tools
			justify-content space-evenly

			VBtn
				&:hover
					color: white
			

			button 
				cursor pointer
				display flex
				border none
				padding 0
				margin 0
				background-color transparent
				justify-content center
				align-items center
				outline none

				svg 
					display flex
					width 15px
					fill second-color

				&:hover svg 
					fill main-color


</style>