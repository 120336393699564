<template>
	<v-card>
		<v-layout>
			<MainMenu 
			:is_user_logged="is_user_logged" 
			:isAdmin="isAdmin()" 
			:info_button_bottom="!!show_vehicle_info"
			@showLogin="show_login = !show_login"
			@showRoutes="show_boats_route=!show_boats_route"
			@showNextWaypoint="show_next_waypoint = !show_next_waypoint"
			@showWaypoints="show_waypoints = !show_waypoints"
			@showWind="show_wind = !show_wind"
			@layers="changeLayers">

				<SearchMission :is_user_logged="is_user_logged"
					:is_logged_user_admin="user_perms.some(e => e.name == 'show_admin_panel')"
					:show_recomend_search="show_recomend_search" @add="addMission" @show_login="show_login = true"
					@input_search_focused="show_recomend_search = true" />


			</MainMenu>
			<v-main>
				<div class="map-container">
					<Map ref="map" :show_boats_route="show_boats_route" :show_next_waypoint="show_next_waypoint"
						:show_waypoints="show_waypoints" :show_wind="show_wind" :zoomControl="true" :layers="layers"
						@showInfo="showBoatInfo" @mapFocused="show_recomend_search = false"
						@mapUnfocused="show_recomend_search = false">
					</Map>
					<BoatInfo v-if="show_vehicle_info " :vehicle="show_vehicle_info" @close="show_vehicle_info = false" />
			
					

					<SelectedMissionsList 	@goToMissionPosition="goToMissionPosition" :newMission = "new_added_mission" 
						@closeVehicleInfo="show_vehicle_info = false"/>
						
					<LoginModal v-if="show_login" @close="show_login = false" />


				</div>

			</v-main>
		</v-layout>
	</v-card>
</template>
  
<script>
import {ref, onBeforeMount, onMounted, watch} from 'vue';
import MapRenderer from '@/components/Map.vue';
import BoatInfo from '@/components/boatInfo/BoatInfo.vue';
import SearchMission from '@/components/searchMission/SearchMission.vue';
import SelectedMissionsList from '@/components/selectedMissions/SelectedMissionsList.vue';
import LoginModal from '@/components/LoginModal.vue';
import IconNextWaypoint from 'vue-material-design-icons/MapMarkerDistance.vue';
import IconWaypoints from 'vue-material-design-icons/Map.vue';
import IconWind from 'vue-material-design-icons/WeatherWindy.vue';
import axios from 'axios';
import {  useInventoryStore } from '@/store';



import MainMenu from '@/components/Menu.vue'

export default {
	name: 'MapView',
	components: {
		Map: MapRenderer,
		BoatInfo,
		SearchMission,
		SelectedMissionsList,
		LoginModal,
		IconNextWaypoint,
		IconWaypoints,
		IconWind,
		MainMenu
	},
	props: {
		is_user_logged: Boolean,
		user_perms: Array
	},
	setup(props) {
		const map = ref({});
		const inventoryStore = useInventoryStore();
		const show_vehicle_info = ref(null);
		const show_boats_route = ref(false);
		const show_next_waypoint = ref(false);
		const show_waypoints = ref(false);
		const show_wind = ref(false);
		const show_login = ref(false);
		const show_recomend_search = ref(false);
		const show_depth_contour = ref(false);
		const show_marine_profile = ref(false);
		const new_added_mission = ref(null);

		const layers = ref([]);
		
		
		
		const showBoatInfo = (boat) => {
			
			show_vehicle_info.value = boat;
			
		};

		const goToMissionPosition = (mission) => {
			const record = mission.vehicle.records[mission.vehicle.current_playing_record];
			const latLang = [record.latitude, record.longitude];
			map.value.updatePosition(latLang);
		}
		
		const addMission = (new_mission) => {
			new_added_mission.value = new_mission;		
			setTimeout(() => {
				new_added_mission.value = null;
			}, 200);
		};

		
		const changeLayers = (event) => {
			layers.value = event;
		}

		const isAdmin = () => { return props.user_perms.some(e => e.name === 'show_admin_panel') };

		return {
			show_vehicle_info,
			show_boats_route,
			show_next_waypoint,
			show_waypoints,
			show_wind,
			show_login,
			show_recomend_search,
			show_depth_contour,
			show_marine_profile,
			changeLayers,
			showBoatInfo,
			addMission,
			goToMissionPosition,
			isAdmin,
			map,
			new_added_mission,
			layers
		};
	},
	
};
</script>
  
<style lang="stylus" scoped>
  @import "../assets/css/generalColors.styl";

  .info-button-no-vehicle-info {
	top: 20px;
	right: 10px;
	position: absolute;
	z-index: 1000;
  }

  .info-button-with-vehicle-info {
	bottom : 20px;
	right: 10px;
	position: absolute;
	z-index: 1000
  }
  
  .map-container {
	height: 100vh;
	position: relative;
  }
  
  .control-buttons {
	position: absolute;
	z-index: 2002;
	bottom: 10px;
	left: 10px;
  
	button {
	  border: none;
	  border-radius: 5px;
	  background-color: transparent;
	  color: var(--main-color);
	  padding: 3px 5px 1px 5px;
	  font-weight: bold;
	  cursor: pointer;
  
	  &:not(:last-child) {
		margin-right: 10px;
	  }
  
	  &:hover {
		background-color: var(--third-color);
		box-shadow: 0px 0px 11px 0px rgba(0, 0, 0, 0.15);
	  }
  
	  &.active {
		background-color: var(--main-color);
		box-shadow: 0px 0px 11px 0px rgba(0, 0, 0, 0.15);
  
		svg {
		  fill: var(--third-color);
		}
	  }
  
	  svg {
		height: 30px;
		fill: var(--main-color);
	  }
	}
  }

  $list-color{
	
  }
  
  .login-container {
	position: absolute;
	top: 5px;
	right: 5px;
	z-index: 2002;
  
	svg {
	  width: 40px;
	}
  }
  </style>
  