<template>
	<div class="general">
        <div class="general-width">
            <GeneralHeader 
                :item="vehicle" 
                :type="'Vehicle'" 
                :title="vehicle.name"
                :validation_target="'name'" 
                :can_edit="user_perms.find(e => e.name == 'edit_vehicle')" 
                :can_delete="user_perms.find(e => e.name == 'delete_vehicle')" 
                @edit="editVehicle($event)" 
                @delete="deleteVehicle($event)" 
            />
            <VehicleInfo 
                :vehicle="vehicle" 
                :user_perms="user_perms"
            />
        </div>
    </div>
</template>

<script>
import GeneralHeader from '@/components/admin/layouts/GeneralHeaderCard'
import VehicleInfo from '@/components/admin/vehicles/VehicleInfo.vue'
import axios from 'axios'
import { useMyStore } from '@/store'

export default {
    name:"VehiclePage",
    components:{
        GeneralHeader,
        VehicleInfo
    },
    beforeMount (){
        axios.get(`${useMyStore().api_url}/admin/vehicle/${this.$route.params.id}`)
        .then(response => {
            this.vehicle = response.data.data
        }).catch(e => {
            console.log(e);
        });
    },
    data (){
        return {
            vehicle : {}
        }
    },
    props:['user_perms'],
    methods: {
        editVehicle: function(input_vehicle){
            axios.put(`${useMyStore().api_url}/admin/vehicle/${this.$route.params.id}`, input_vehicle)
                .then((response) => {
                    
                    Object.assign(this.vehicle, response.data.data);
                    useMyStore().addNotification({
                        type: 'success',
                        title: 'Edited Vehicle'
                    })
                }).catch(e => {
                    console.log(e);
                    useMyStore().addNotification({
                        type: 'error',
                        title: `Error editing vehicle`
                    })
                });
        },
        deleteVehicle: function(){
            axios.delete(`${useMyStore().api_url}/admin/vehicle/${this.$route.params.id}`)
                .then(() => {
                    this.$router.push({name:'AdminVehicles'})
                    useMyStore().addNotification({
                        type: 'success',
                        title: 'Deleted vehicle'
                    })
                }).catch(e => {
                    console.log(e);
                    useMyStore().addNotification( {
                        type: 'error',
                        title: `Error deleting vehicle`
                    })
                });
        }
    },
}
</script>
<style lang="stylus" scoped>

</style>