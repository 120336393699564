<template>
	<div class="error-container">
		<h1>
			ERROR 404
		</h1>
		<p>
			Lo sentimos, la página que estás buscando no se ha encontrado.
		</p>
	</div>
</template>

<style lang="stylus" scoped>
.error-container
    font-family: 'Arial', sans-serif
    text-align: center
    height: 100vh /* Establece la altura del contenedor al 100% de la altura de la ventana */
    display: flex /* Para alinear verticalmente el contenido */
    flex-direction: column /* Alinea el contenido en columna */
    justify-content: center /* Centra verticalmente el contenido */
    align-items: center /* Centra horizontalmente el contenido */

    h1
        font-size: 3rem
        color: #dc3545
        margin-bottom: 20px

    p
        font-size: 1.2rem


</style>

<script>
	export default {
		name: 'Error404',
		mounted() {
			document.title = "404 Not found"
		}
	}
</script>

