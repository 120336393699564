<template>
  <v-no-ssr>
    <div id="app" :class="[isAdminPage() ? 'is_admin' : '']">
      <link rel="preconnect" href="https://fonts.gstatic.com">
      <link rel="manifest" href="manifest.json">
      <link href="https://fonts.googleapis.com/css2?family=Roboto:wght@400;500;700&display=swap" rel="stylesheet">
      <AdminNotifications v-if="myStore.admin_notifications.length > 0" />
      <router-view :user_perms="userPerms" :is_user_logged="isUserLogged" />
    </div>
  </v-no-ssr>

</template>

<style>
#app {
  font-family: 'Avenir', 'Helvetica', 'Arial', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
}

.is_admin {
  display: flex;
  height: 100%;
  width: 100%;
  flex-direction: column;
}

@media (min-width: 500px) {
  .is_admin {
    flex-direction: unset;
    align-items: flex-start;
  }
}

body {
  margin: 0;
  height: 100vh;
  background-color: lightblue;
  color: #363636;
}

.general {
  padding: 40px 20px;
  flex-grow: 1;
  display: flex;
  flex-wrap: wrap;
  overflow: auto;
  max-height: 100vh;
  box-sizing: border-box;
  justify-content: center;
  scrollbar-width: none ;
}

@media (max-width: 500px) {
  .general {
    padding: 0;
  }
}

.general-width {
  width: 940px;
}

@media (max-width: 1070px) {
  .general-width {
    width: 100%;
  }
}
</style>

<script>
import { ref } from 'vue';
import Menu from '@/components/admin/menu/Menu.vue';
import BottomMenuMobile from '@/components/admin/menu/BottomMenuMobile.vue';
import AdminNotifications from '@/components/admin/notifications/list.vue';
import axios from 'axios';
import { useMyStore, useUserStore } from './store';

export default {
  name: 'App',
  components: {
    Menu,
    BottomMenuMobile,
    AdminNotifications
  },
  setup() {
    const myStore = useMyStore();
    const userStore = useUserStore()
    const menuData = ref([]);
    const isUserLogged = ref(false);
    const userPerms = ref([]);

    
    const isAdminPage = () => {
      userStore.getUserIsAdmin();
      return window.location.pathname.includes('admin') && window.location.name !== 'Error404';
    };

    axios.get(`${myStore.api_url}/getPerms`)
      .then(response => {
        localStorage.setItem('perms', response.data.perms);
        const perms = JSON.parse(response.data.perms);
        userPerms.value = perms;
        isUserLogged.value = response.data.is_logged;
        const menuDataProvisional = [
          {
            route:'/admin',
            icon:'IconHome',
            icon_selected:'IconHomeSelected',
            text:'Inicio',
            required_perm: 'show_admin_panel'
          },
          {
            route:'/admin/missions',
            icon:'IconMissions',
            icon_selected:'IconMissionsSelected',
            text:'Misiones',
            required_perm: 'show_missions'
          },
          {
            route:'/admin/vehicles',
            icon:'IconVehicles',
            icon_selected:'IconVehicles',
            text:'Vehículos',
            required_perm: 'show_vehicles'
          },
          {
            route:'/admin/users',
            icon:'IconAdmins',
            icon_selected:'IconAdminsSelected',
            text:'Usuarios',
            required_perm:'show_users'
          },
          {
            route:'/admin/roles',
            icon:'IconRoles',
            icon_selected:'IconRolesSelected',
            text:'Roles',
            required_perm:'show_roles'
          },
        ];
        menuData.value = menuDataProvisional.filter(element => {
          const elementFinded = perms.find(e => e.name === element.required_perm);
          return elementFinded !== undefined;
        });
      }).catch(e => {
        console.log(e.response);
      });

    return { menuData, isUserLogged, userPerms, isAdminPage, myStore };
  },
  beforeMount() {
    document.title = 'SIANI';
  }
}
</script>
