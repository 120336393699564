<template>
	<div class="search-wrapper">
		<div class="select-container" @blur="search_text='';search_result=false;">
			<div class="select-input-container">
				<input 
					@focus="$emit('input_search_focused')" 
					@keyup="searchNewData" 
					class="select-input" 
					placeholder="Search for the desired mission" 
					type="text" 
					v-model="search_text" 
				>
				<div :class="['select-search-icon', search_text ? 'active' : '' ]">
					<svg viewBox="0 0 24 24">
						<path d="M9.5,3A6.5,6.5 0 0,1 16,9.5C16,11.11 15.41,12.59 14.44,13.73L14.71,14H15.5L20.5,19L19,20.5L14,15.5V14.71L13.73,14.44C12.59,15.41 11.11,16 9.5,16A6.5,6.5 0 0,1 3,9.5A6.5,6.5 0 0,1 9.5,3M9.5,5C7,5 5,7 5,9.5C5,12 7,14 9.5,14C12,14 14,12 14,9.5C14,7 12,5 9.5,5Z" />
					</svg>
				</div>
			</div>
			<div v-if="show_recomend_search && (search_text || missions.length > 0)" :class="['separation-row', !search_result && search_text ? 'animate':'']">
				<div></div>
			</div>
			<div v-if="show_recomend_search && search_text && search_result" class="search-container">
				<div v-if="search_result.length == 0">
					<h3>
						No results found
					</h3>
				</div>
				<SearchMissionElement 
					v-for="mission in search_result"
					:key="mission.id"
					:mission="mission"
					:can_add="[...missions].filter(e => e.id === mission.id).length == 0"
					@add="$emit('add', $event)"
				/>
			</div>
		</div>
		<div v-if="show_recomend_search && search_text == ''">
			<RecomendSearch 
				@add="$emit('add', $event)"
				:selectedMissions="missions"
				:missions="recomended_missions"
				:vehicles="recomended_vehicles"
			/>
		</div>
	</div>
</template>

<style lang="stylus" scoped>
	@import("../../assets/css/generalColors.styl")
	.search-wrapper
		position absolute
		top 10px
		left 10px
		z-index 2002
		width 95%
		max-width 400px

	.select-container
		display flex
		flex-direction column
		background-color third-color 
		padding 10px 20px 10px 20px
		border-radius 8px
		place-items center
		-webkit-box-shadow 0px 0px 11px 0px rgba(0,0,0,0.15)
		-moz-box-shadow 0px 0px 11px 0px rgba(0,0,0,0.15)
		box-shadow 0px 0px 11px 0px rgba(0,0,0,0.15)

		.tags-container
			display flex 
			flex-wrap wrap
			margin-top 5px
			width 100%

			

		.select-input-container
			width 100%
			display flex

			.select-input
				border-radius 2px
				border none
				background-color transparent
				outline none
				font-family 'Roboto', sans-serif
				font-size 1rem
				color main-color
				width 100%

				&::placeholder
					color second-color

		.select-search-icon 
			display flex
			align-items center
			a
				margin 0
				padding 0
				display flex
			span
				font-weight bold
				font-size 1.1rem
				margin-left 5px
				margin-right 5px
				align-self start
				margin-top 2px


			svg 
				height 25px
				fill second-color

			&.active svg 
				fill main-color

		.separation-row
			transition display 3s linear
			margin-top 10px
			height 3px
			width 100%
			border-radius 99px
			overflow hidden
			position relative

			> div 
				height 100%
				width 100%
				border-radius 99px
				background-color main-color
				opacity 1

			&.animate > div
				animation-name searching
				animation-duration 2s
				animation-iteration-count infinite
				animation-timing-function linear
				position absolute
				left 0
				top 0
				transform: translateX(-100%)
		.search-container
			width 100%
			
	@keyframes searching {
		0% {opacity: 0;transform: translateX(-100%)}
		25% {opacity: 0.8;transform: translateX(0%)}
		50% {opacity: 0;transform: translateX(100%)}
		75% {opacity: 0.8;transform: translateX(0%)}
		100% {opacity: 0;transform: translateX(-100%)}
	}
</style>

<script>
	import {ref, computed} from 'vue';
	import SearchMissionElement from '@/components/searchMission/SearchMissionElement'
	import RecomendSearch from '@/components/searchMission/RecomendSearch'
	import axios from 'axios'
	import { useMyStore, useInventoryStore } from '@/store'
	export default {
		name: 'SelectMission', 
		components: {
			SearchMissionElement,
			RecomendSearch
		},
		props:['is_user_logged', 'is_logged_user_admin', 'show_recomend_search'],
		setup(){
			const search_text = ref("");
			const search_result = ref(false);
			let keyup_timeout = false;
			const recomended_missions = ref([]);
			const recomended_vehicles = ref([]);

			const inventoryStore = useInventoryStore();
			const missions_ref = computed(() => inventoryStore.playing_missions);
			const missions = ref(missions_ref.value);

			const searchNewData = () => {
				search_result.value = false
				if(keyup_timeout){
					clearTimeout(keyup_timeout);
				}
				keyup_timeout = setTimeout(() => {
					axios.post(`${useMyStore().api_url}/searchForMission`,{
						search: search_text.value
					})
						.then(response => {
							search_result.value = response.data.missions
						}).catch(e => {
							console.log(e.response);
						});
					keyup_timeout = false
				}, 2000)
			}

			return {
				search_text,
				search_result,
				keyup_timeout,
				recomended_missions,
				recomended_vehicles,
				searchNewData,
				missions
			}

		}
		
	}
</script>