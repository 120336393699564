<template>
    <div class="info-container">
        <div class="info-menu-container">
            <button :class="['dashboard', show_dashboard ? 'selected':'']" @click="changePanel(DASHBOARD)">Info</button>
            <button :class="['vehicles', show_vehicles ? 'selected':'']" @click="changePanel(VEHICLES)">Vehicles</button>
            <button v-if=" user == mission.owner || isAdmin" :class="['permissions', show_permissions ? 'selected':'']" @click="changePanel(PERMISSIONS)">Permissions</button>
        </div>
        <div v-if="show_dashboard" class="dashboard-wrapper">
            <div 
                v-for="item in dashboard_items" 
                :key="item.id" 
                class="mission-dashboard-item"
            >
                <p class="label">
                    {{item.label}}
                </p>
                <p class="content">
                    {{
                        item.target === 'start_date' || item.target === 'end_date' ?
                            getFormatedDate(mission[item.target]) :
                        item.target === 'is_public' || item.target === 'active_mission' ?
                            (mission[item.target] === 1 ? "Yes" : "No") :
                        item.target === 'owner' ?
                            (getOwner(mission[item.target]) ? "" : owner) :
                        mission[item.target]
                    }}
                </p>
            </div>
        </div>
        <GeneralList 
            v-if="show_vehicles" 
            :items="mission_vehicles" 
            type="MissionVehicle"
            :item_general_info="item_general_info"
            :item_additional_info="item_additional_info" 
            :validation_target="'name'" 
            :can_add="user_perms.find(e => e.name == 'edit_mission') && mission_vehicles.length === 0"
            :can_delete="user_perms.find(e => e.name == 'edit_mission')"
            :can_add_csv="user_perms.find(e => e.name == 'edit_mission')"
            @uploadCSV="uploadCSV($event)"
            @add="addMissionVehicle($event)"
            @delete="deleteMissionVehicle($event)"
        />

        <PermissionsTable
            v-if="show_permissions"
            :missionId="mission.id"
            :missionOwner="mission.owner"
            :isPublic="mission.is_public === 1"
            @modified="modified_permissions = $event"
        />

        <ConfirmDialog 
            :dialog="confirmDialog" 
            @confirm="confirmDialogResponse('confirm')" 
            @cancel="confirmDialogResponse('cancel')"/>


    </div>
</template>
<script>
    import GeneralList from '@/components/admin/lists/GeneralList'
    import PermissionsTable from './PermissionsTable'
    import ConfirmDialog from './ConfirmDialog'
    import { useMyStore, useUserStore } from '@/store';
    import axios from 'axios'
    
    axios.defaults.headers.common = {
        Authorization: `${localStorage.getItem('token')}`
    };


    export default {
        name:'missionInfo',
        components:{
            GeneralList,
            PermissionsTable,
            ConfirmDialog
        },
        props:['mission', 'user_perms'],
        beforeMount() {
            this.dashboard_items = [
                {
                    label: 'Name',
                    target: 'name'
                },
                {
                    label: 'Location',
                    target: 'location'
                },
                {
                    label: 'Start Date',
                    target: 'start_date'
                },
                {
                    label: 'End Date',
                    target: 'end_date'
                },
                {
                    label: 'Description',
                    target: 'description'
                },
                {
                    label:"The mission is public",
                    target : "is_public"
                },
                {
                    label:"The mission is active",
                    target : "active_mission"
                },
                {
                    label:"Owner",
                    target: "owner"
                }
            ]
            this.item_additional_info = []
            this.item_general_info = {
                should_redirect: false,
                fields: [
                    {
                        component: 'h2',
                        target: 'name'
                    }
                ]
            }
            
            axios.get(`${useMyStore().api_url}/admin/mission/${this.$route.params.id}/vehicles`)
                .then(response => {
                    this.mission_vehicles = response.data.data
                }).catch(e => {
                    console.log(e);
                });
        },
        data() {
            return {
                show_dashboard : true,
                show_vehicles : false,
                show_permissions : false,
                dashboard_items : [],
                mission_vehicles : [],
                item_additional_info : [],
                item_general_info : {},
                DASHBOARD : "dashboard",
                VEHICLES : "vehiles",
                PERMISSIONS : "permissions",
                modified_permissions : false,
                confirmDialog : false,
                skip : false,
                user : localStorage.getItem('userId'),
                isAdmin : useUserStore().isAdmin,
                owner : ""
            }
        },
        methods: {
            waitForCondition : async function (conditionCallback) {
                return new Promise((resolve, reject) => {
                    const checkCondition = async () => {
                        if (conditionCallback()) {
                            resolve();
                        } else {
                            // Espera un breve período antes de verificar la condición nuevamente
                            setTimeout(checkCondition, 50); 
                        }
                    };

                    checkCondition();
                });
            },
            addMissionVehicle: function(data){
                
                axios.put(`${useMyStore().api_url}/admin/mission/${this.$route.params.id}/vehicles/${data.vehicle}`)
                    .then(response => {
                        this.mission_vehicles = response.data.data
                    }).catch(e => {
                        console.log(e);
                    });
            },
            deleteMissionVehicle: function(item){
                axios.delete(`${useMyStore().api_url}/admin/mission/${this.$route.params.id}/vehicles/${item.id}`)
                    .then(response => {
                        this.mission_vehicles = response.data.data
                    }).catch(e => {
                        console.log(e);
                    });
            },
            changePanel : async function( panel ) {
                if(this.modified_permissions) {
                    this.confirmDialog = true;
                    await this.waitForCondition(() => this.confirmDialog === false);
                    if(!this.skip){
                        return;
                    }
                    
                }
                this.modified_permissions = false;
                this.show_dashboard = panel === this.DASHBOARD;
                this.show_vehicles = panel === this.VEHICLES;
                this.show_permissions = panel === this.PERMISSIONS;
            },  
            confirmDialogResponse: function (response) {
                this.skip = response === "confirm";
                this.confirmDialog = false;
            },
            uploadCSV: function(data){
                data.mission_id = this.mission.id
                if(data.file.name.match(/\.csv$/i)){
                    this.sendCSV(data)  
                              
                    if(data.file.size > 100000){
                        useMyStore().addNotification({
                        type: 'warning',
                        title: 'Very large file',
                        description: 'It will take some time for the file to be added'
                        });
                    }else{
                        useMyStore().addNotification({
                        type: 'warning',
                        title: 'Uploading file',
                        description: 'The file is being processed, it will be finished shortly'
                        });
                    }
                    
                }else
                    useMyStore().addNotification({
                        type: 'error',
                        title: 'Incorrect extension',
                        description: 'The file must be a .csv'
                    });
            },
            sendCSV: function(data){
                const reader = new FileReader();
                reader.readAsText(data.file);
                
                reader.onload = () => {
                    axios.post(`${useMyStore().api_url}/admin/mission/uploadCSV`, {
                        mission_id : data.mission_id,
                        vehicle_id : data.vehicle_id,
                        file_data: this.csvToJson(reader.result)//[...new Map(this.csvToJson(reader.result).map(item =>[item.datetime, item])).values()]
                    })
                        .then(() => {
                            useMyStore().addNotification( {
                                type: 'success',
                                title: 'Added CSV'
                            })
                        }).catch(e => {
                            console.log(e);
                            useMyStore().addNotification( {
                                type: 'error',
                                title: 'Error reading the file'
                            })
                        });
                };
            },
            csvToJson: function(csv_data){
                let lines = [];
                const linesArray = csv_data.split('\n');
                // for trimming and deleting extra space 
                linesArray.forEach((e) => {
                    const row = e.replace(/[\s]+[,]+|[,]+[\s]+/g, ',').trim();
                    lines.push(row);
                });
                // for removing empty record
                lines.splice(lines.length - 1, 1);
                const result = [];
                const headers = lines[0].split(",");

                for (let i = 1; i < lines.length; i++) {

                    const obj = {};
                    const currentline = lines[i].split(",");

                    for (let j = 0; j < headers.length; j++) {
                        if(headers[j].replace(/"/g, '') == 'datetime')
                            obj[headers[j].replace(/"/g, '')] = currentline[j].replace(/"/g, '').replace(/\.[0-9]*$/g, '')
                        else
                            obj[headers[j].replace(/"/g, '')] = currentline[j].replace(/"/g, '')
                    }
                    result.push(obj)
                }
                //return result; //JavaScript object
                // return JSON.stringify(result); //JSON
                return result;
            },addZeroIfLessThanTen: function(number = 0){
                return number < 10 ? "0" + number : number;
            },
            getFormatedDate: function (date) {
                if(date === undefined || date === 0 || date === null) return "--------"
                return new Date(date).toLocaleString();
            },
            getOwner: function (id) {
                axios.get(`${useMyStore().api_url}/admin/user/${id}`)
                .then((response) => {
                    this.owner = response.data.data[0]?.name;
                })
                return false;
            }

            
        },
    }
</script>
<style lang="stylus" scoped>
@import("../../../assets/css/generalColors.styl")
general-radius = 8px

.info-container
    background-color rgba(255,255,255,0.8)
    border-radius general-radius

    @media (max-width: 500px)
        border-radius 0

    .dashboard-wrapper
        background-color rgba(255,255,255,0.8)
        border-bottom-left-radius 8px
        border-bottom-right-radius 8px
        display grid 
        grid-template-columns repeat(auto-fit, minmax(400px, 1fr))
        grid-gap 30px
        padding 20px

        @media (max-width: 500px)
            grid-template-columns 1fr

        .mission-dashboard-item
            p
                padding 0
                margin 0
                text-align start
            
                &.label
                    font-size 0.9rem
                    font-weight bold
                    margin-bottom 5px
                    
                &.content
                    font-size 1.3rem
                    margin-left 15px

    .info-menu-container
        display flex

        button
            flex-grow 1
            font-size 1.2rem
            padding 10px 20px
            border none
            background-color transparent
            outline none
            cursor pointer
            color #363636

            &:first-child
                border-top-left-radius general-radius
                @media (max-width: 500px)
                    border-top-left-radius 0
            &:last-child
                border-top-right-radius general-radius
                @media (max-width: 500px)
                    border-top-right-radius 0
                
            &.selected
                font-weight bold
                background-color rgba(255,255,255,0.8)
                border-top-right-radius general-radius
                border-top-left-radius general-radius
            
</style>