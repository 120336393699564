<template>

    <v-dialog v-model="loading_mission" max-width="320" persistent>
        <v-list color="primary" elevation="12" rounded="lg" style="scrollbar-width: none;">
            <v-list-item prepend-icon="mdi-ferry" title="Loading..." >
                <template v-slot:prepend>
                    <div class="pe-4">
                        <v-icon color="primary" size="x-large"></v-icon>
                    </div>
                </template>

                <template v-slot:append>
                    <v-progress-circular color="primary" indeterminate="disable-shrink" size="16"
                        width="2"></v-progress-circular>
                </template>
            </v-list-item>
        </v-list>
    </v-dialog>
</template>

<script>
import { watch, ref } from 'vue';

export default {
    name : "ChargeDialog",
    props : ["loading_mission"],
    setup(props) {
        const loading_mission = ref(false);

        watch(() => props.loading_mission, (value) => {
            loading_mission.value = value;
        })
        return {
            loading_mission
        }
    }
}
</script>