<template>
    <v-text-field 
        :label="field.label" 
        @update:modelValue="$emit('changingValue');"
        variant="solo"
        :rules="rules"
        v-model="field.value"
        placeholder="0:0:0:0" 
        :hint="field.hint"
        bg-color="red"
        type="email">
    </v-text-field>
</template>

<script>
export default {
    name: "MACField",
    props : ['field'],
    setup() {
        const rules = [
        value => !!value || 'Required.',
        value => (value || '').length <= 20 || 'Max 20 characters',
      ]

        return {
            rules
        };
    }
}
    
</script>