
export const checkWaypoints = (waypoints, current_time) => {
    const waypointsArray = JSON.parse(waypoints);
    const current_time_number = new Date(current_time).getTime(); 
    let resultWaypoint = waypointsArray[0];

    waypointsArray.forEach(waypointsObject => {
        const last_time = new Date(resultWaypoint.timestamp).getTime();
        const object_time = new Date(waypointsObject.timestamp).getTime();

        if(object_time <= current_time_number && object_time > last_time) {
            resultWaypoint = waypointsObject;
        }
        
    });

    const resultObject = {
        is_cyclic : resultWaypoint.is_cyclic,
        latLngs : []
    }

    resultWaypoint.nodes.forEach(index => {
        resultObject.latLngs.push([resultWaypoint.latitudes[index], resultWaypoint.longitudes[index]]);
    });

    if(resultObject.is_cyclic) {
        resultObject.latLngs.push(
            [resultWaypoint.latitudes[resultWaypoint.nodes[1]], 
            resultWaypoint.longitudes[resultWaypoint.nodes[1]]]);
    }

    return JSON.stringify(resultObject);
}