<template>
    <div class="notifications-container">
        <AdminNotificationItem 
            :notification="store.admin_notifications[0]"
            @removeNotification="removeNotification"
        />
    </div>
</template>

<style lang="stylus">
    .notifications-container
        position fixed 
        top 30px
        left 50%
        transform translateX(-50%)
        z-index 99999
</style>

<script>
    import { useMyStore } from '@/store'
    import { ref } from 'vue'
    import { onBeforeMount } from 'vue'
    import AdminNotificationItem from './item'

    export default {
        name: 'AdminNotifications',
        data() {
            return {
                interval_control:false
            }
        },
        components:{
            AdminNotificationItem,
        },
        setup(){
            let interval_control = false;
            const store = ref(useMyStore());
            const createInterval =  () => {
                interval_control = setInterval(() => {
                    //quitamos elemento
                    removeNotification()
                    if(useMyStore().admin_notifications.length <= 0){
                        clearInterval(interval_control)
                    }
                },3000)
            }

            const removeNotification = () => {
                clearInterval(interval_control)
                useMyStore().removeNotification();
                createInterval()
            }
            onBeforeMount(()=>{
                if(!interval_control){
                    createInterval()
                }
            });

            return {
                removeNotification,
                store
            }

        },
    }
</script>




