import { onMounted } from 'vue';
<template>
    <v-checkbox 
        class="field" 
        v-model="field.value" 
        :id="field.name"                
        :label="field.label" 
        
        @change="$emit('changingValue');" 
        @focus="is_focused=true" 
        @blur="is_focused=false" >
    </v-checkbox>
</template>

<script>
export default {
    name:"GeneralCheckboxField",
    props:["field"],
    data() {
        return {
            is_focused: false,
            validation: true
        }
    }
}
</script>