<template>
    <div class="pa-4 text-center">
        <v-dialog v-model="dataDialog" max-width="600">
            <v-card>
                <v-card-item>
                    <v-card-title>
                        Select options
                    </v-card-title>

                </v-card-item>

                <v-card-item>
                    <v-row align-content="center">

                        <v-col cols="auto" md="4" sm="4">
                            <h5>Interval (Sec)</h5>

                            <v-number-input :min="1" :model-value="15" controlVariant="split"
                                variant="plain" density="compact" center-affix v-model="interval"></v-number-input>
                        </v-col>

                        <v-spacer></v-spacer>

                        <v-col cols="auto" md="4" sm="4">
                            <h5>Data type</h5>

                            <v-radio-group v-model="recordsType" :disabled="active_mission">
                                <v-radio v-for="recordType in types" :label="recordType.label" :value="recordType.value"></v-radio>
                            </v-radio-group>
                        </v-col>
                    </v-row>

                </v-card-item>

                <v-card-actions>
                    <v-btn color="red" variant="plain" text="Cancel" @click="cancel"></v-btn>

                    <v-btn color="green" variant="plain" text="Accept" @click="setOptions"></v-btn>
                </v-card-actions>


            </v-card>
        </v-dialog>
    </div>
</template>

<script>
import { watch } from 'vue';
import {computed, ref } from 'vue';
    export default {
        name : "DataDialog",
        props: ['dialog', 'active_mission'],
        emits : ['close', 'setOptions', 'cancel',],
        setup(props, ctx) {
            const dataDialog = computed({
                get : () => props.dialog,
                set : (val) => {
                    if(!val) {
                        ctx.emit('close')
                    }
                }
            });

            const types = ref([{
                    label : 'History records',
                    value : 'csv'}, 
                {
                    label : 'Live records',
                    value : 'live'}]);

            const interval = ref(60);
            const recordsType = ref(types.value[0].value);

            watch(() => props.active_mission, (value) => {
                recordsType.value = value ? types.value[1].value : types.value[0].value;
            })

            const cancel = () => {
                ctx.emit('cancel');
            }

            const setOptions = () => {
                ctx.emit('setOptions',interval.value, recordsType.value);
            }

            return {
                dataDialog,
                cancel,
                setOptions,
                types,
                interval,
                recordsType
            }

                
        }

    }
</script>