<template>
	<div>
	  <div class="container">
		<div v-if="live" class="live">
		  <svg viewBox="0 0 24 24">
			<path
			  d="M12,2A10,10 0 0,0 2,12A10,10 0 0,0 12,22A10,10 0 0,0 22,12A10,10 0 0,0 12,2Z"
			/>
		  </svg>
		</div>
		<div class="info">
		  <p class="name">{{ title }}</p>
		  <p v-if="(type ==='missions' || type === 'vehicle_mission') && missionIsDisabled()" class="more-info">{{ missionDisableReason() }}</p>
		</div>
		<div class="tools">
		  <button
			v-for="button in buttons"
			:key="button.id"
			@click="handleButtonClick(button)"
			:disabled="button.disabled"
		  >
			{{ button.text }}
		  </button>
		</div>
	  </div>
	  <div v-if="show_additional_info" class="additional-info">
		{{ additional_info }}
	  </div>
	</div>
  </template>
  
  <style lang="stylus" scoped>
	@import("../../assets/css/generalColors.styl")
	.container
		display flex
		margin-bottom 8px
		min-height 35px

		.live 
			align-self center
			svg
				display flex
				height 15px
				width 15px
				margin-right 15px
				fill red

		.info
			flex-grow 1
			align-self center

			p
				text-align start
				margin 0
				padding 0

			.name
				font-size 1.2rem

			.more-info
				font-size 0.9rem
				color red

		.tools
			display flex

			button
				border none
				background-color second-color 
				color third-color
				border-radius 99999999px
				
				&:not(:last-child)
					margin-right 5px

				&:hover
					background-color main-color
				
				&:disabled
					background-color gray
					color second-color
			
			
</style>
  
<script>
import { ref, computed } from 'vue';
import { useInventoryStore } from '@/store';

export default {
	name: "RecomendSearchListElement",
	props: {
		id: Number,
		title: String,
		additional_info: String,
		buttons: Array,
		live: Boolean,
		type: String
	},
	emits:['info', 'add', 'search_by_vehicle'],
	setup(props, context) {
		const inventory = useInventoryStore();
		const show_additional_info = ref(false);
		const handleButtonClick = (button) => {
			if (button.emit === 'additional_info') {
				show_additional_info.value = !show_additional_info.value;
			} else {
				context.emit(button.emit);
			}
		};

		inventory.$onAction(({name}) => {
			if(name === "addPlayingMission" || name === "removePlayingMission") {
				props.buttons.find(button => button.text === "Add").disabled = 
					![...inventory.playing_missions].some(item => item.id === props.id);
			}
		});

		const missionIsDisabled = () => {
			return props.buttons.find(button => button.text === "Add").disabled;
		}

		const missionDisableReason = () => {
			const mission = inventory.missions.find(mission => mission.id === props.id);
			if(mission.vehicles.length === 0) {
				return "No Assigned Vehicle."
			}
			if(mission.no_records){
				return "No route data mapped."
			}
			
			return "The mission is already playing";
		}

		return {
			handleButtonClick,
			show_additional_info,
			missionIsDisabled,
			missionDisableReason
		}
	}
}
</script>
  