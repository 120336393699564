<template>
	<div class="search-container">
		<h2 v-if="type === 'vehicles'">
			Vehicles
		</h2>
		<h2 v-else-if="type === 'missions' || type === 'vehicle_mission'">
			Missions
		</h2>
		<v-btn density="compact" class="ma-2"  icon="mdi-arrow-left" @click="$emit('hide')" color="black"></v-btn>
		<template v-if="type === 'missions' || type === 'vehicle_mission' ">
			<v-tabs
			v-model="tab"
			color="primary"
			align-tabs="center"
			>

			<v-tab value="finished" variant="plain" >
				<v-icon start> mdi-album</v-icon>
				History</v-tab>

			<v-divider vertical></v-divider>
			<v-tab value="active" variant="plain">
				<v-icon start> mdi-access-point</v-icon>
				Actives</v-tab>
			
		</v-tabs>
		<v-divider></v-divider>
		<v-window v-model="tab">
			<v-window-item value="finished">
				<RecomendSearchListElement v-for="item in list.filter(m => m.active_mission === 0)" :type ="type" :id="item.id" :title="item.name" :live="false"
					:additional_info="item.description" :buttons="calculateButtons(item)" @info="showInfo" @add="$emit('add',item)"
					@search_by_vehicle="$emit('search_by_vehicle', item)" />
			</v-window-item>
				
			<v-window-item value="active">
				<RecomendSearchListElement v-for="item in list.filter(m => m.active_mission === 1)" :type ="type" :id="item.id" :title="item.name" :live="true"
					:additional_info="item.description" :buttons="calculateButtons(item)" @info="showInfo" @add="$emit('add',item)"
					@search_by_vehicle="$emit('search_by_vehicle', item)" />
			</v-window-item>
		</v-window>
		</template>
		<RecomendSearchListElement v-else v-for="item in list" :type ="type" :id="item.id" :title="item.name" :live="false"
			:additional_info="item.description" :buttons="calculateButtons(item)" @info="showInfo" @add="$emit('add',item)"
			@search_by_vehicle="$emit('search_by_vehicle', item)" />
		
		
	</div>
</template>
  
<style lang="stylus" scoped>
	@import("../../assets/css/generalColors.styl")
	.search-container
		padding 10px
		padding-bottom 0

		h2
			margin 0
			margin-bottom 5px
			color second-color
</style>
  
<script>
import { ref } from 'vue'
import RecomendSearchListElement from './RecomendSearchListElement.vue';
import { useInventoryStore } from '@/store';
export default {
	name: 'RecommendSearchList',
	components: {
		RecomendSearchListElement,
	},
	emits: ['search_by_vehicle', 'hide','add'],
	props: ['list', 'type', 'missions'],
	setup(props) {
		const tab = ref("")
		const inventory = useInventoryStore();
		
		const organizeMissions = (isFinished = true) => {
			let a = props.list.filter(mission => isFinished === (mission.active_mission === 0));
			return a;
		}

		const finished = ref(organizeMissions());
		const active = ref(organizeMissions(false));

		const showInfo = ()  => {
			console.log('INFO!');
		}
		
		const calculateLiveStatus = (item) => {
			
			return (
				item &&
				item.start_date &&
				item.end_date &&
				new Date(item.start_date).getTime() <= new Date().getTime() &&
				new Date().getTime() < new Date(item.end_date).getTime()
			);
		}
		const calculateButtons = (item) => {
			if (props.type === 'missions' || props.type === 'vehicle_mission') {
				return [
					{ text: 'Info', emit: 'additional_info' },
					{
						text: 'Add',
						emit: 'add',
						disabled:
							inventory.missions.find(e => e.id === item.id).vehicles.length === 0 || 
							[...inventory.playing_missions].some(mission => mission.id === item.id) ||
							inventory.missions.find(e => e.id === item.id).no_records
					},
				];
			} else {
				return [{ text: 'Search', emit: 'search_by_vehicle' }];
			}
		}

		return {
			showInfo,
			calculateLiveStatus,
			calculateButtons,
			tab,
			finished,
			active
		}
	},
};
</script>
  