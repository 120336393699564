import axios from 'axios';
import { defineStore } from 'pinia';



let api_url = 'http://localhost:3000/api';
let tm_url = 'http://localhost:3000/live';
if(process.env.NODE_ENV != "development"){
	api_url = 'https://atirma.iusiani.ulpgc.es/api';
	tm_url = 'https://atirma.iusiani.ulpgc.es/live';
	
}



export const useMyStore = defineStore('myStore', {
  state: () => ({
    admin_notifications: [],
	api_url : api_url,
	tm_url : tm_url,
	items_per_page : 1000
  }),
  actions : {
		addNotification(notification){
			this.admin_notifications.push(notification)
		},
		removeNotification(){
			this.admin_notifications.shift()
		},

		pageNumber(index) {
			return Math.floor(index / this.items_per_page);
		}
  }
});

export const useUserStore = defineStore('userStore', {
	state: () => ({
		isAdmin : false,
	}),
	actions : {
		async getUserIsAdmin(){
			const token = localStorage.getItem('token');
			if(token == "" || token == null || token == undefined) {
				this.isAdmin = false;
				return;
			}
			const isAdmin = (await axios.get(`${api_url}/admin/user/isAdmin/${token}`)).data;
			this.isAdmin = isAdmin;
		}
	}
});

export const useInventoryStore = defineStore('inventoryStore', {
	state: () => ({
		vehicles: [],
		missions: [],
		playing_missions: new Set(),
		followingMission : [-1]
	}),
	actions : {
		updateVehicles(vehicles) { 
			this.vehicles = vehicles ?? [];
		},

		updateMissions(missions) {
			this.missions = missions ?? [];
		},

		addPlayingMission(mission) {
			this.playing_missions.add(mission);
		},
		removePlayingMission(mission) {
			this.playing_missions.delete(mission)
		},
		removeAllPlayingMissions() {
			this.playing_missions.clear();
		},
		addRecordToLiveMission(liveMission, record) {
			this.playing_missions.forEach(playing_missions => {
				if(liveMission === playing_missions){
					playing_missions.vehicle.records.push(record);
					playing_missions.count++;
					return;
				}
				
			})
		},
		addWaypointsToLiveMission(liveMission, waypoints) {
			

			this.playing_missions.forEach(playing_missions => {
				if(liveMission === playing_missions){
					
					playing_missions.vehicle.waypoints.push({
						nodes : JSON.parse(waypoints.nodes),
						latitudes : JSON.parse(waypoints.latitudes),
						longitudes : JSON.parse(waypoints.longitudes),
						is_cyclic : waypoints.is_cyclic,
						timestamp : waypoints.timestamp

					});
					return;
				}

				
				
			})
		},

		getFollowingMission() {
			return this.followingMission;
		},

		getPlayingMissionPosition() {
			let result = [];
			this.playing_missions.forEach(playing_mission => {
				if(playing_mission.id === this.followingMission[0]){
					const index = playing_mission.vehicle.current_playing_record;
					result = [
						playing_mission.vehicle.records[index].latitude,
						playing_mission.vehicle.records[index].longitude
					]
				}
			})

			return result;
		}
		
	}
});


