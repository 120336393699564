<template>
	<div class="selected-missions-container"> 
		<SelectedMissionItem 
			v-for="mission in missions" 
			:key="mission.id" 
			:mission="mission"
			@goToMissionPosition="$emit('goToMissionPosition', $event)"
			@close="deleteMission" 
			@changing_data="loading_mission=$event"
		/>
	</div>

	<data-dialog :dialog="dialog" @close="dialog = false" @cancel ="dialog = false" @setOptions="setOptions" :active_mission="active_mission"/>
	<charge-dialog :loading_mission="loading_mission"/>
</template>

<style lang="stylus" scoped>
	@import("../../assets/css/generalColors.styl")
	.selected-missions-container
		display: flex;
		width: 100%;
		flex-wrap: wrap;
		justify-content: center;
		position absolute
		bottom 70px
		z-index 999
</style>

<script>
	import SelectedMissionItem from '@/components/selectedMissions/SelectedMissionItem.vue';
	import { useInventoryStore, useMyStore } from '@/store';
	import { ref, computed, watch } from 'vue';
	import axios from 'axios';
	import DataDialog from './DataDialog.vue';
	import ChargeDialog from './ChargeDialog.vue';
	import app from '../../main';
	export default {
		name: 'SelectedMissionsList', 
		components: {
			SelectedMissionItem,
			DataDialog,
			ChargeDialog
		},
		props : ['newMission'],
		emits: ['deleteMission', 'goToMissionPosition', 'newMission', 'closeVehicleInfo'],
		setup(props, ctx){
			const inventoryStore = useInventoryStore();
			const store = useMyStore();
			const missions_ref = computed(() => inventoryStore.playing_missions);
			const missions = ref(missions_ref.value);
			const missionUpdateIntervalMap = new Map();
			const active_mission = ref(false);
			const loading_mission = ref(false);

			const dialogOptions = {
				interval : 0,
				recordsType : 0
			}
			const dialog = ref(false);

			app.config.globalProperties.$router.beforeEach(() => {
				inventoryStore.removeAllPlayingMissions();
				missionUpdateIntervalMap.forEach(value => {
					clearInterval(value);
				})

				missionUpdateIntervalMap.clear();
			});

			window.addEventListener('beforeunload', (event) => {
				missions.value.forEach(value => {
					const body = {
						user: localStorage.getItem('user'),
						mission_id: value.id
					}

					liveMissionUnsubscribe(body);

				})
				console.log("Adios");
				return '';
			});

			const setOptions = (interval, recordsType) => {
				dialogOptions.interval = interval;
				dialogOptions.recordsType = recordsType;
				dialog.value = false;
			}

			const waitForData = () => {
				return new Promise((resolve, reject) => {
					const checkVariable = () => {
						if (!dialog.value) {
							if(dialogOptions.interval === 0 || dialogOptions.recordsType === ''){
								resolve("No data");
							}
							resolve('ok');
						} else {
							setTimeout(checkVariable, 500); // Espera 1 segundo antes de revisar de nuevo
						}
					};
					return checkVariable();
				});
			};

			const liveMissionUnsubscribe = async (body) => {
				await axios.post(`${store.tm_url}/unsubscribe`, body);
			}

			const liveMissionSubscribe = async (body) => {
				try {
					const OK_RESPONSE = 'ok';
					const response = await axios.post(`${store.tm_url}/subscribe`, body)

					if (response.data === OK_RESPONSE) {
						return 'ok';
					} else if (response.status === 409) {
						await liveMissionUnsubscribe(body);
						return await liveMissionSubscribe(body);
					}
					return response.status;
				} catch (error) {
					if(error.response.status === 409){
						useMyStore().addNotification({
                        type: 'warning',
                        title: 'Mission is already playing',
                        description: 'The mission is already playing with this user. If you have open sessions, close them and try again. Otherwise, you will not receive live data.'
                        });
					}
					return error.response.status;
				}
				
			}


			const addMission = async (new_mission) => {
				dialog.value = true;
				active_mission.value = new_mission.active_mission === 1;
				
				const waiting = await waitForData();
				if(waiting != 'ok') {
					return;
				}

				loading_mission.value = true;
				const interval = dialogOptions.interval;
				const recordsType = dialogOptions.recordsType;
				dialogOptions.interval = 0;
				dialogOptions.recordsType = '';

				


				new_mission.current_playing_date = new Date(new_mission.start_date).getTime();
				new_mission.current_playing = false;
				new_mission.is_loading = true;
				new_mission.recording_interval = 1000;
				new_mission.interval = interval;
				new_mission.recordsType = recordsType;

				const response = await axios
					.get(`${store.api_url}/getNumberOfRecordsFromMission/${new_mission.id}/${recordsType}/${interval}`);
				new_mission.count = response.data[0].count;

				if(new_mission.count === 0) {
					store.addNotification({
                        type: 'error',
                        title: `The mission has no records with that kind of data.`
                    });
					loading_mission.value = false;
					return;
				}
				
				const page = store.pageNumber(new_mission.active_mission ? new_mission.count - 1 : 0);
				await axios
					.get(`${store.api_url}/getRecordsFromMission/${new_mission.id}/${page}/${recordsType}/${interval}`)
					.then((response) => {
						new_mission.vehicle = { ...response.data.vehicles };
						new_mission.vehicle.records = new Array(new_mission.count).fill(0);
						const initialIndex = page * store.items_per_page;
						for (let index = 0 ; index < response.data.vehicles.records.length; index++) {
							new_mission.vehicle.records[initialIndex + index] = response.data.vehicles.records[index];
						}
						new_mission.vehicle.current_playing_record = new_mission.active_mission ? new_mission.vehicle.records.length - 1 : initialIndex;
						new_mission.vehicle.latLngs = [[[
							new_mission.vehicle.records[new_mission.vehicle.current_playing_record].latitude,
							new_mission.vehicle.records[new_mission.vehicle.current_playing_record].longitude
						]]];

						
						new_mission.is_loading = false;
						//new_mission.current_playing = true;
						loading_mission.value = false;	


					})
					.catch((e) => {
						console.error(e);
						loading_mission.value = false;
					});
				
				new_mission.vehicle.waypoints = [];
				new_mission.waypoints = {
					latLngs : [],
					is_cyclic : false
				}
				const waypointsResponse = await axios.get(`${store.api_url}/getWaypointsFromMission/${new_mission.id}`);
				if(waypointsResponse.data.length != 0) {
					const waypoints = [];
					waypointsResponse.data.forEach(value => {
						value.nodes = JSON.parse(value.nodes);
						value.latitudes = JSON.parse(value.latitudes);
						value.longitudes = JSON.parse(value.longitudes);
						value.is_cyclic = value.is_cyclic === 1;
						value.timestamp = new Date(value.timestamp).getTime();
						waypoints.push(value);
					})
					new_mission.vehicle.waypoints.push(...waypoints);
				}
				inventoryStore.addPlayingMission(new_mission);


				if (new_mission.active_mission) {
					const body = {
						user: localStorage.getItem('user'),
						mission_id: new_mission.id
					}
					const status = await liveMissionSubscribe(body);
					if (status === 'ok') {
						const missionUpdateInterval = setInterval(async () => {

							try {
								const response = await axios.get(`${store.tm_url}/getData`, {
									params: { ...body }
								});

								if (response.data.records != null && response.data.records != undefined) {
									inventoryStore.addRecordToLiveMission(new_mission, response.data.records);
								}
								if (response.data.waypoints != null && response.data.waypoints != undefined) {
									inventoryStore.addWaypointsToLiveMission(new_mission, response.data.waypoints);
								}
							} catch (error) {
								if(error.response?.status === 404) {
									liveMissionSubscribe(body)
								}
							}
							
						}, 3000);
						missionUpdateIntervalMap.set(new_mission.id, missionUpdateInterval)

					} else new_mission.active_mission = false;
					new_mission.current_playing = true;

				}		
				
			};

			const deleteMission = (mission) => {
				inventoryStore.removePlayingMission(mission);
				ctx.emit('closeVehicleInfo');
				if(mission.active_mission){
					const body = {
						user : localStorage.getItem('user'),
						mission_id : mission.id
					}

					liveMissionUnsubscribe(body);
					clearInterval(missionUpdateIntervalMap.get(mission.id));
					missionUpdateIntervalMap.delete(mission.id);
				}

			};

			watch(() => props.newMission, (newValue) => {
				if(newValue != null){
					addMission(newValue);
				}
			});
			return {
				missions,
				deleteMission,
				dialog,
				setOptions,
				active_mission,
				loading_mission
			};
		}
	}
</script>