<template>
    <v-navigation-drawer expand-on-hover rail permanent style="max-width: fit-content;">


        <div class="div_image">
            <v-img src="/logo.png" style="max-width: 5vw;"></v-img>
        </div>


        <v-divider></v-divider>

        <template v-if="is_user_logged">
            <v-list slim density="compact">
                <v-list-item prepend-icon="mdi-account" :title="userName" value="login" color="blue-lighten-4"
                    disabled variant="tonal" ></v-list-item>
            </v-list>

            <v-divider></v-divider>
        </template>


        <v-list density="compact" nav open-strategy="single" slim variant="plain">
            <v-list-item v-if="!is_user_logged" prepend-icon="mdi-account" title="Log in" value="login"
                @click="$emit('showLogin')" :active=false color="blue-lighten-4"></v-list-item>
            <v-list-item v-if="is_user_logged" prepend-icon="mdi-logout" title="Log out" value="logout" @click="logout"
                :active=false></v-list-item>

            <v-list-item v-if="is_user_logged " prepend-icon="mdi-cogs" title="Manage" value="settings"
                @click="active('admin')" :active=false></v-list-item>
            <v-list-item prepend-icon="mdi-magnify" title="Search" value="search" @click="active('search')"
                :active=false></v-list-item>

        </v-list>

        <v-divider>
        </v-divider>

        <v-list density="compact" nav slim variant="plain" select-strategy="independent" color="primary">
            <v-list-item prepend-icon="mdi-map-marker-path" title="Route" value="route"
                @click="$emit('showRoutes')"></v-list-item>
            <v-list-item prepend-icon="mdi-map-marker-distance" title="Active waypoint" value="next-waypoint"
                @click="$emit('showNextWaypoint')"></v-list-item>
            <v-list-item prepend-icon="mdi-map-marker-multiple" title="Waypoints" value="waypoints"
                @click="$emit('showWaypoints')"></v-list-item>
            <v-list-item prepend-icon="mdi-weather-windy" title="Wind" value="wind"
                @click="$emit('showWind')"></v-list-item>
        </v-list>

        <v-divider></v-divider>



        <v-list density="compact" nav slim variant="plain" select-strategy="single-leaf" color="primary">
            <v-list-item prepend-icon="mdi-layers-triple" value="layers" title="Layers" :active=false>
                <v-menu activator="parent" location="end" :close-on-content-click="false" width="max-content">
                    <v-list density="compact" slim nav>
                        <v-list-item>
                            <v-checkbox-btn v-model="layersArray" label="Bathymetric contours"
                                value="Bathymetric contours"></v-checkbox-btn>
                        </v-list-item>
                        <v-list-item>
                            <v-checkbox-btn v-model="layersArray" label="High Resolution bathymetry"
                                value="High Resolution bathymetry"></v-checkbox-btn>
                        </v-list-item>
                        <v-list-item>
                            <v-checkbox-btn v-model="layersArray" label="Mean depth in multi colour (no land)"
                                value="Mean depth in multi colour (no land)"></v-checkbox-btn>
                        </v-list-item>
                        <v-list-item>
                            <v-checkbox-btn v-model="layersArray" label="Mean depth rainbow colour (no land)"
                                value="Mean depth rainbow colour (no land)"></v-checkbox-btn>
                        </v-list-item>
                    </v-list>
                </v-menu>
            </v-list-item>
        </v-list>


    </v-navigation-drawer>
    <v-navigation-drawer v-model="show_admin_panel" temporary location="right">
        <v-list density="compact" nav open-strategy="single" lines="two" slim variant="plain">
            <v-list-item prepend-icon="mdi-sail-boat" :active=false @click="show_dialog('vehicles')" title="Vehicles"
                value="vehicles"></v-list-item>

            <v-list-item prepend-icon="mdi-compass" :active=false @click="show_dialog('missions')" title="Missions"
                value="missions"></v-list-item>

            <v-list-item v-if="isAdmin" prepend-icon="mdi-account-group" :active=false @click="show_dialog('users')"
                title="Users" value="users"></v-list-item>

            <!-- <v-list-item v-if="isAdmin" prepend-icon="mdi-lock" :active=false @click="show_dialog('rols')" title="Roles"
                value="rols"></v-list-item> -->

        </v-list>
    </v-navigation-drawer>
    <v-navigation-drawer v-model="show_search_panel" temporary location="right" :width="400">
        <slot></slot>
    </v-navigation-drawer>

    

    <v-overlay v-model="dialog" location="top" style="justify-content:center;align-items: center;">
        <AdminVehiclesPage v-if="overlay_types.vehicles" :user_perms="user_perms"></AdminVehiclesPage>
        <AdminMissionsPage v-if="overlay_types.missions" :user_perms="user_perms"></AdminMissionsPage>
        <AdminUsersPage v-if="overlay_types.users" :user_perms="user_perms"></AdminUsersPage>
        <AdminRolsPage v-if="overlay_types.rols" :user_perms="user_perms"></AdminRolsPage>

        <InfoModal v-if="overlay_types.info" :isAdmin="isAdmin"></InfoModal>
    </v-overlay>

    <v-btn :class="info_button_bottom ? 'info-button-bottom' :'info-button-top'" icon="mdi-information-variant" density="comfortable" @click="show_dialog('info')"></v-btn>
</template>
<script>
import { ref, watch, onMounted} from 'vue';
import AdminVehiclesPage from '@/views/admin/Vehicles.vue';
import AdminMissionsPage from '@/views/admin/Missions.vue';
import AdminUsersPage from '@/views/admin/Users.vue';
import AdminRolsPage from '@/views/admin/Roles.vue';
import InfoModal from './InfoModal.vue'


export default {
    name: 'MainMenu',
    components: {
        AdminVehiclesPage,
        AdminMissionsPage,
        AdminUsersPage,
        AdminRolsPage,
        InfoModal
    },
    props: {
        is_user_logged: Boolean,
        isAdmin: Boolean,
        info_button_bottom : Boolean
        
    },
    emits: ['showLogin','showRoutes','showNextWaypoint','showWaypoints','showWind','layers'],
    setup(_, context) {
        const userName = ref("");
        const layersArray = ref([]);
        const overlay_types = ref(
            {
                vehicles:false,
                missions:false,
                users:false,
                rols:false,
                info:false
            }
        );
        const show_vehicle_overlay = ref(false);
        const show_admin_panel = ref(false);
        const show_search_panel = ref(false);
        const dialog = ref(false);
        const logout = () => {
            localStorage.clear();
            location.reload();
        };

        const active = (panel) => {
            show_admin_panel.value = panel === "admin";
            show_search_panel.value = panel !== "admin";
        }
        const perms = localStorage.getItem('perms');
        const user_perms = perms ? JSON.parse(perms) : [];

        const show_dialog = (overlay_type) => {
            Object.keys(overlay_types.value).forEach(key => {
                overlay_types.value[key]=false;
            });
            overlay_types.value[overlay_type] = true;
            dialog.value = true;
        }

        const depthsOptions = { 1 : {name :'showDepthContour', activated : false} ,
                                2 : {name :'showMarineProfile', activated : false}}

        const showDepths = (emitOption) => {
            const otherOption = emitOption === 1 ? 2 : 1;
            if(!depthsOptions[emitOption].activated && !depthsOptions[otherOption].activated ) {
                
                depthsOptions[emitOption].activated = true;
                context.emit(depthsOptions[emitOption].name);
                return;
            
            }

            if(depthsOptions[emitOption].activated){
                depthsOptions[emitOption].activated = false;
                context.emit(depthsOptions[emitOption].name);
                return;
            }

            depthsOptions[emitOption].activated = true;
            depthsOptions[otherOption].activated = false;

            context.emit(depthsOptions[emitOption].name);
            context.emit(depthsOptions[otherOption].name);
        }

        onMounted(() => {
            userName.value = localStorage.getItem("user");
        })

        watch(layersArray, () => {
            context.emit('layers', layersArray.value);
        });
        return {
            logout,
            show_admin_panel,
            show_search_panel,
            show_vehicle_overlay,
            active,
            dialog,
            user_perms,
            overlay_types,
            show_dialog,
            showDepths,
            layersArray,
            userName
        }

    }
}
</script>
<style scoped>
    .info-button-top {
        top: 20px;
        right: 10px;
        position: absolute;
        z-index: 1000;
    }

    .info-button-bottom {
        bottom : 20px;
        right: 10px;
        position: absolute;
        z-index: 1000
    }
    .opacity-0{
        opacity: 0;
    }

    .div_image{
        display: flex;
        justify-content: center;
        margin: 5px;
    }
</style>